<template>
  <div>
    <div class="pos-layout">
      <div class="main">
        <div class="p-1 col-container">
          <pos-navbar />
          <searchbar
            v-if="!(restockMode === true || cashWithdrawalMode === true || promoValidationMode === true
              || supplierPaymentMode === true || airtimeRechargeMode === true
              || servicePaymentMode === true || loanPaymentMode === true || cashPaymentMode === true
              || bankDepositMode === true || codiPaymentMode === true)"
          />
          <!-- Tab content for fav products and services grid -->
          <b-tabs
            class="tabs-main"
            content-class="overflow-auto"
            @input="handleChangeTab"
          >
            <b-tab
              title="Menú"
              active
            >
              <services-grid />
            </b-tab>
            <b-tab title="Favoritos" class="h-100 ">
              <starred-items v-if="starredProducts" />
            </b-tab>
            <b-tab title="Car" v-if="isMobile" class="h-100"
              :active="showCartModal"
            >
              <div class="tab-cart h-100">
                <cart />
                <order-info />
              </div>

              <template v-slot:title>
                <div class="d-flex justify-content-between align-items-center">
                  <feather-icon
                    icon="ShoppingCartIcon"
                    size="18"
                  />
                </div>
                </template> 
            </b-tab>
          </b-tabs>
          <fetch-products-modal />
        </div>
        <status-bar />
      </div>
      <div v-if="!isMobile" class="pos-grid">
        <cart />
        <order-info />
      </div>
    </div>
    <PosSettings />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex' 
import Cart from '@/views/pos/Cart.vue'
import PosNavbar from '@/views/pos/PosNavbar.vue'
import OrderInfo from '@/views/pos/OrderInfo.vue'
import Searchbar from '@/views/pos/SearchBar.vue'
import ServicesGrid from '@/views/pos/ServicesGrid.vue'
import PosSettings from '@/components/PosSettingsSidebar.vue'
import FetchProductsModal from '@/views/pos/FetchProductsModal.vue'
import StatusBar from '@/views/pos/StatusBar.vue'
import StarredItems from '@/views/pos/StarredItems.vue'

let bulkOrdersInterval

// tab-content overflow

export default {
  components: {
    Cart,
    PosNavbar,
    OrderInfo,
    Searchbar,
    PosSettings,
    ServicesGrid,
    FetchProductsModal,
    StatusBar,
    StarredItems,
  },
  data() {
    return {
      searchQuery: '',
      screenWidth: window.innerWidth,
      newCartItem: false,

    }
  },
  computed: {
    ...mapGetters('nfcService', ['enc']),
    ...mapGetters('pos', ['lunrCtx', 'showCartModal', 'cart', 'offlineMode', 'starredProducts', 'restockMode',
      'cashWithdrawalMode', 'supplierPaymentMode', 'airtimeRechargeMode', 'promoValidationMode',
      'productInventoryMode', 'servicePaymentMode', 'loanPaymentMode', 'cashPaymentMode', 'bankDepositMode', 'codiPaymentMode', 'cart']),
      
    isMobile(){
      return this.screenWidth < 770 
    },
  },
  watch: {
    lunrCtx(val) {
      if (!val.fieldVectors || val.fieldVectors.length === 0) {
        this.handleFetchOffline()
      }
    },
  },
  mounted() {
    this.handleFetchOffline()
    this.startNfcService()
    bulkOrdersInterval = setInterval(() => {
      this.dispatchBulkOrders(this.$route.params.store_id)
        .catch(err => {
          /* eslint-disable-next-line */
          console.log(err)
        })
    }, 120000) // After 2 minutes 120000
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    this.resetOfflineProducts()
    clearTimeout(bulkOrdersInterval)
    this.setShowCartModal(false)
  },
  methods: {
    ...mapMutations('pos', ['resetOfflineProducts', 'setShowCartModal']),
    ...mapActions('orders', ['dispatchBulkOrders', 'deleteOrdersFromIndexedDb']),
    ...mapActions('nfcService', ['startNfcService']),
    ...mapActions('storeProducts', ['fetchOfflineStoreProducts']),

    handleFetchOffline() {
      this.$bvModal.show('loadingOffline')
      this.fetchOfflineStoreProducts(this.$route.params.store_id)
    },
    handleResize(){
      this.screenWidth = window.innerWidth
    },

    handleChangeTab(tabIndex) {
      if (tabIndex !== 2) {
        this.setShowCartModal(false)
      } 
    }

  },
}
</script>

<style lang="scss" scoped>

.pos-layout {
  max-height: 100vh;
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 770px) {
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
  }
}

.tab-content{
  overflow-y: auto !important;
}

.pos-grid {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "cart"
    "info";
  max-height: 100vh;
  height: 100vh;
}

.main {
  max-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

.col-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
  max-height: 100vh;
}

.tabs-main {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: auto;
}

.tab-cart {
  display: grid;
  grid-template-rows: auto 1fr;
}

::-webkit-scrollbar {
  display: none;
}


</style>
