<template>
  <div class="w-100">
    <b-button
      tabindex="5"
      variant="shadow"
      size="lg"
      block
      class="w-100 h-100 payment-button"
      @click="completeSaleWithCredit($event)"
    >
      <b-img :src="require('@/assets/images/logo/reward_points.png')" width="80" />
      <span class="py-2">Crédito</span>
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  computed: {
    ...mapGetters('pos', ['cart', 'cartTotal', 'restockMode', 'currentRestockSupplier']),
    ...mapGetters('promotions', ['promoCodePromotion']),
  },
  methods: {
    ...mapActions('orders', ['addOrder']),
    ...mapActions('printer', ['print']),
    ...mapActions('pos', [
      'emptyCart',
    ]),
    ...mapMutations('pos', [
      'SET_RESTOCK_MODE',
      'SET_SUPPLIER',
    ]),
    eliminarDiacriticos(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    completeSaleWithCredit() {
      const order = {
        store_supplier_id: this.restockMode === true ? this.currentRestockSupplier.id : null,
        is_loan: true,
        store_id: this.$route.params.store_id,
        total: this.cartTotal,
        store_clerk: JSON.parse(localStorage.getItem('userData')),
        promo_code: this.promoCodePromotion ? this.promoCodePromotion.promo_code : '',
        order_store_products_attributes: this.cart.map(product => ({
          store_product_id: product.id,
          units: product.units,
        })),
        order_payments_attributes: [
          {
            payment_type: 'cash',
            amount: this.cartTotal
          },
        ],
      }

      const setOrderType = this.restockMode === true ? 'buy' : 'sell'

      this.addOrder({ order, orderType: setOrderType })
        .then(() => {
          this.SET_RESTOCK_MODE(false)
          this.SET_SUPPLIER(null)
          if (JSON.parse(localStorage.getItem('print')) === true) {
            // window.print()

            const orderToPrint = {
              order_type: {
                payment: 'walleat',
                type: setOrderType,
              },
              store: this.eliminarDiacriticos(this.cart[0].store_attributes.name),
              products: this.cart.map(product => ({
                name: this.eliminarDiacriticos(product.product_attributes.name),
                variant: product.product_attributes.variant,
                units: product.units,
                unit_price: product.unit_price,
              })),
              subtotal: this.cartTotal - this.cartTotal * 0.16,
              iva: this.cartTotal * 0.16,
              total: this.cartTotal,
              date: Date.now(),

            }
            this.print({ ...orderToPrint })
          }

          // eslint-disable-next-line
          const audio = new Audio(require('@/assets/sounds/Success.wav'))
          audio.play()
          this.$swal({
            title: 'Cobro exitoso!',
            text: 'Gracias.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          this.emptyCart()
          this.$bvModal.hide('cash-numpad')
        }).catch(error => {
          this.SET_RESTOCK_MODE(false)
          this.SET_SUPPLIER(null)
          if (error.response.data.messages[0]) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: error.response.data.messages[0],
              },
            })
          }
        })
        .finally(() => {
          this.$bvModal.hide('payments-modal')
        })
    },
  },
}
</script>

  <style lang="scss" scoped>
  .modal-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .payment-button {
    font-size: 1.2rem !important;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    font-size: 1rem !important;

    @media (min-width: 480px) { 
      font-size: 1.2rem !important;

      img {
        width: 100px;
      }
    
    }
    
    @media (min-width: 768px) {
      font-size: 1.5rem !important;

    }
  }

</style>
