<template>
  <div>
    <div
      v-b-modal.nfc-loan-modal
      class="service-card return-card"
      :class="loanPaymentMode === true ? 'active-btn' : null"
      @click="SET_LOAN_PAYMENT(true)"
    >
      <i class="fas fa-2x fa-hand-holding-usd mb-1" />
      Abono a deuda
    </div>
    <b-modal
      id="nfc-loan-modal"
      hide-footer
      @hidden="SET_LOAN_PAYMENT(false)"
    >
      <b-row class="mb-2" v-if="!enc && !walleatName">
        <b-col class="text-center">
          <h1 class="">
            Escanear Walleat
          </h1>
          <p>
            acerca el Walleat a tu dispositivo con NFC
          </p>
          <div>
            <b-img
              src="https://thumbs.gfycat.com/LawfulWavyIlsamochadegu-small.gif"
              width="200"
              rounded
            />
          </div>
        </b-col>
      </b-row>
      <NFC />
    </b-modal>
  </div>
</template>

<script>
import NFC from '@/views/e-commerce/e-commerce-checkout/NfcLoanPaymentService.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    NFC,
  },
  data() {
    return {
      isActive: false,
      walleatName: null,
    }
  },
  computed: {
    ...mapGetters('pos', ['loanPaymentMode']),
    ...mapGetters('nfcService', ['enc']),
  },
  methods: {
    ...mapMutations('pos', ['SET_LOAN_PAYMENT']),
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.active-btn {
  background-color: $primary !important;
}

</style>
