<template>
  <b-button
    tabindex="2"
    variant="shadow"
    size="md"
    block
    class="w-100 h-100 payment-button"
    @click="handleClick()"
  >
    <b-img :src="require('@/assets/images/logo/CODI.svg')" width="100" />
  </b-button>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
   
    }
  },
  methods: {
    ...mapMutations('pos', ['SET_CODI_PAYMENT_MODE']),
    handleClick(){
      this.SET_CODI_PAYMENT_MODE(true)
      this.$bvModal.show('pos-preview-modal')
    }
  }
}
</script>

<style lang='scss' scoped>
  .payment-button {
  font-size: 1.2rem !important;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 !important;
  font-size: 1rem !important;

  @media (min-width: 480px) { 
    font-size: 1.2rem !important;

    img {
      width: 100px;
    }
  
  }
  
  @media (min-width: 768px) {
    font-size: 1.5rem !important;

  }
}
</style>

