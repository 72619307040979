<template>
  <div>
    <div
      v-b-modal.select-supplier
      tabindex="-1"
      class="service-card supplier-card"
      :class="restockMode === true ? 'active' : null"
    >
      <i class="fas fa-2x fa-boxes mb-1" />
      Comprar a proveedor
    </div>
    <b-modal
      id="select-supplier"
      @ok="completeSupplierRestock()"
      @cancel="SET_RESTOCK_MODE(false)"
    >
      <b-row class="d-flex justify-content-center">
        <!-- Pagination -->
        <b-col
          v-if="suppliers && suppliers.length > 0"
          cols="12"
          class="d-flex justify-content-end mb-1"
        >

          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="19"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="19"
              />
            </template>
          </b-pagination>
        </b-col>

        <b-col sm="auto">
          <b-button
            v-for="supplier in suppliers"
            :key="`supplier-${supplier.id}`"
            :variant="supplierData === supplier ? 'info' :'primary'"
            class="m-1"
            block
            @click="supplierData = supplier"
          >
            {{ supplier.name }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data() {
    return {
      supplierData: null,
      suppliers: null,
      pagination: null,
    }
  },
  computed: {
    ...mapGetters('pos', ['cart', 'cartTotal', 'restockMode']),
  },
  created() {
    this.fetchStoreSuppliers({ by_store: this.$route.params.store_id })
      .then(response => {
        this.suppliers = response.data
        this.pagination = response.meta.pagination
      })
  },
  methods: {
    ...mapActions('orders', ['addOrder']),
    ...mapActions('printer', ['print']),
    ...mapActions('pos', [
      'emptyCart',
    ]),
    ...mapActions('suppliers', ['fetchStoreSuppliers']),
    ...mapMutations('pos', [
      'SET_RESTOCK_MODE',
      'SET_SUPPLIER',
    ]),
    completeSupplierRestock() {
      if(!this.supplierData) return
      this.SET_RESTOCK_MODE(true)
      this.SET_SUPPLIER(this.supplierData)
    },
    handlePagination({ page }) {
      this.fetchStoreSuppliers({
        by_store: this.$route.params.store_id,
        meta: { pagination: { page } },
      })
        .then(response => {
          this.suppliers = response.data
          this.pagination = response.meta.pagination
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.active {
  background-color: $primary !important;
}

</style>
