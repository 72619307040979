<template>
  <div>
    <div
      v-if="userImg && enc"
      class="d-flex justify-content-center mt-2 mb-1"
    >
      <b-img
        thumbnail
        rounded
        :src="userImg"
        width="200"
      />
    </div>
    <div class="d-flex justify-content-center flex-column align-items-center">
      <div
        v-if="!walleatName"
        class="p-5"
      >
        <i class="fas fa-spinner fa-spin fa-3x" />
      </div>
      <b-badge
        v-if="walleatName"
        variant="light-warning my-1"
      >
        {{ walleatName }}
      </b-badge>
    </div>

    <div v-if="bannedItems.length">
      <h5 class="text-warning">
        Retira los siguientes productos de la orden para continuar.
      </h5>
      <product-card
        v-for="product in bannedItems"
        :key="`item-${product.id}`"
        :product="product"
      />
      <b-button
        class="mb-2"
        block
        @click="removeBannedProductsFromCart(bannedItems)"
      >
        Retirar productos
      </b-button>
    </div>

    <div>
      <b-button-group
        v-if="enc && walleatName"
        class="w-100"
      >
        <b-button
          variant="danger"
          @click="cancelSale()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="success"
          :disabled="bannedItems.length > 0"
          @click="completeSale()"
        >
          Continuar
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import {
  BButton, BImg, BButtonGroup, BBadge,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import ProductCard from './ProductCard.vue'

export default {
  components: {
    BButton,
    BImg,
    BButtonGroup,
    ProductCard,
    BBadge,
  },
  data() {
    return {
      nfcStatus: null,
      userImg: null,
      walleatName: null,
      bannedItems: [],
    }
  },
  computed: {
    ...mapGetters('pos', ['cartTotal', 'cartTotalProducts', 'cart']),
    ...mapGetters('nfcService', ['enc']),
  },
  watch: {
    enc: debounce(function searchQuery(query) {
      if (query) {
        this.fetchWalleatByEnc(query).then(response => {
          this.userImg = response.data.logo
          this.walleatName = response.data.name
        })
      } else {
        this.userImg = null
        this.walleatName = null
      }
    }, 250),
  },
  mounted() {},
  methods: {
    ...mapActions('walleats', ['fetchWalleatByEnc']),
    ...mapActions('orders', ['addOrder', 'dispatchOrder']),
    ...mapActions('pos', ['emptyCart']),
    ...mapMutations('pos', ['deleteProductFromCarts']),
    ...mapMutations('nfcService', ['SET_ENC']),
    completeSale() {
      const tempCart = []
      this.cart.forEach(product => {
        const refactorProduct = {
          store_product_id: product.id,
          units: product.units,
        }
        tempCart.push(refactorProduct)
      })
      const orderReady = {
        bracelet_number: this.enc,
        store_id: this.$route.params.store_id,
        order_store_products_attributes: tempCart,
        order_payments_attributes: [
          {
            payment_type: 'walleat',
            amount: this.cartTotal
          },
        ],
      }
      this.dispatchOrder({ order: orderReady, orderType: 'sell' })
        .then(() => {
          // eslint-disable-next-line
          const audio = new Audio(require("@/assets/sounds/Success.wav"))
          audio.play()
          this.$swal({
            title: 'Cobro exitoso!',
            text: 'Gracias.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.emptyCart()
          this.cancelSale()
        })
        .catch(error => {
          if (error.response.status === 418) {
            this.showLoanConfirmationModal(error)
          } else {
            // eslint-disable-next-line
            const audio = new Audio(require("@/assets/sounds/Denied.wav"))
            audio.play()
            if (navigator.vibrate) {
              navigator.vibrate(200)
            }
            this.bannedItems = error.response.data.banned_items
            const baseMsg = error.response.data.messages?.base ?? null
            if (baseMsg != null) {
              this.$swal({
                title: 'ERROR!',
                text: error.response.data.messages.base[0],
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          }
        })
    },
    showLoanConfirmationModal(errorMsg) {
      this.$bvModal.msgBoxConfirm('El WallEat no cuenta con suficientes fondos, ¿desea fiarle?', {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const tempCart = []
            this.cart.forEach(product => {
              const refactorProduct = {
                store_product_id: product.id,
                units: product.units,
              }
              tempCart.push(refactorProduct)
            })
            const orderReady = {
              bracelet_number: this.enc,
              store_id: this.$route.params.store_id,
              order_store_products_attributes: tempCart,
              order_payments_attributes: [
                {
                  payment_type: 'walleat',
                  amount: this.cartTotal
                },
              ],
              is_loan: true,
            }
            this.dispatchOrder({ order: orderReady, orderType: 'sell' })
              .then(() => {
                // eslint-disable-next-line
                const audio = new Audio(require("@/assets/sounds/Success.wav"))
                audio.play()
                this.$swal({
                  title: 'Cobro exitoso!',
                  text: 'Gracias.',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.emptyCart()
                this.cancelSale()
              })
              .catch(error => {
                // eslint-disable-next-line
                const audio = new Audio(require("@/assets/sounds/Denied.wav"))
                audio.play()
                if (navigator.vibrate) {
                  navigator.vibrate(200)
                }
                this.bannedItems = error.response.data.banned_items
                const baseMsg = error.response.data.messages?.base ?? null
                if (baseMsg != null) {
                  this.$swal({
                    title: 'ERROR!',
                    text: error.response.data.messages.base[0],
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
                if (error.response.data.messages.insufficient_funds[0]) {
                  this.$swal({
                    title: 'ERROR!',
                    text: error.response.data.messages.insufficient_funds[0],
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                  this.emptyCart()
                  this.cancelSale()
                }
              })
          } else {
            this.$swal({
              title: 'ERROR!',
              text: errorMsg.response.data.messages.insufficient_funds[0], // Es cuando excedio el limite de establecimiento, para prestamo
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.cancelSale()
          }
        })
    },
    cancelSale() {
      this.walleatName = null
      this.userImg = null
      this.SET_ENC(null)
      this.$bvModal.hide('nfc-modal')
    },
    removeBannedProductsFromCart(products) {
      products.forEach(product => {
        this.cart.forEach(prod => {
          if (prod.product_attributes.id === product.id) {
            this.deleteProductFromCarts(prod)
          }
        })
      })
      this.bannedItems = []
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
