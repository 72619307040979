<template>
  <b-modal
    :id="`book-product-modal-${product.id}-${product.reference}`"
    title="Referencias"
  >
    <div class="d-flex justify-content-center align-items-center" v-if="book">
      <b-button
        :disabled="book.storeProducts[product.id] === 1"
        class="units-btn"
        @click="handleDecreaseReferenceQuantity()"
      >
        <i class="fas fa-minus" />
      </b-button>
      <input
        id="weight"
        v-model="book.storeProducts[product.id]"
        type="number"
        class="bg-transparent text-white display-3 units border-1 border-primary rounded text-center"
        @click="$bvModal.show('cash-numpad')"
      > 
      <b-button
        class="units-btn"
        @click="handleIncreaseReferenceQuantity(product)"
      >
        <i class="fas fa-plus" />
      </b-button>
    </div>
    
    <template #modal-footer>
      <div
        class="d-flex justify-content-center w-100"
      >
        <b-button 
          type="button"
          class="mr-1"
          variant="outline-danger"
          @click="handleRemoveReferenceOption()">
          Borrar
        </b-button>
        <b-button
          variant="success"
          @click="handleOk()"
        >
          Aceptar cambios
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data() {
    return {}
  },
  props: {
    product: {
      type: [Object, null],
      default: () => {},
    },
    handleIncreaseReferenceQuantity: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('pos', ['bookReferences', 'booksWithReference', 'cart']),

    book() {
      return this.booksWithReference.find((book) => book.reference === this.product.reference && book.storeProducts[this.product.id] > 0)
    }
  },
  methods: {
    ...mapMutations('pos', [
      'decrementBookWithReference',
      'removeOneCartItem',
      'deleteSingleBooksWithReference',
      'decrementProductQuantity'
    ]),
    ...mapActions('pos', [
      'addProductToCart',
    ]),

    handleOk() {
      this.$bvModal.hide(`book-product-modal-${this.product.id}-${this.product.reference}`)
    },
    handleDecreaseReferenceQuantity() {
      const selectedProduct = this.cart.find((product) => product.id === this.product.id)
      this.decrementProductQuantity(selectedProduct)
      this.decrementBookWithReference({
        reference: this.product.reference,
        productId: this.product.id
      })
    },
    handleRemoveReferenceOption(){
      this.$swal({
        title: '¿Estás seguro?',
        text: "No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, cancelar!',
        confirmButtonText: 'Si, borrarlo!',
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          const cartProduct = this.cart.find((product) => product.id === this.product.id)
          Array.from({ length: this.book.storeProducts[this.product.id] }).forEach(() => {
            this.removeOneCartItem(cartProduct)
          })
          this.deleteSingleBooksWithReference({
            reference: this.product.reference,
            productId: this.product.id
          })
          this.$bvModal.hide(`book-product-modal-${this.product.id}-${this.product.reference}`)
        }
      })
    },
  },
}

</script>

<style lang="scss" scoped>
  .units-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid white;
    margin: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }
  .units {
    min-width: 100px;
  }
</style>
