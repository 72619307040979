<template>
  <div>
    <b-button
      tabindex="1"
      variant="outline-light"
      block
      @click="openModal()"
    >
      Código descuento
    </b-button>
    <b-modal v-model="modalVisible" id="validate-promo-code" title="Validar código de descuento" :no-close-on-backdrop="true" hide-header-close>
      <b-form-group label="Código de descuento:">
        <b-form-input v-model="promoCode"></b-form-input>
      </b-form-group>
      <template #modal-footer>
        <b-button @click="validatePromoCode" variant="primary">Validar</b-button>
        <b-button @click="closeModal">Cancelar</b-button>
      </template>
    </b-modal>
  </div>
  
</template>

<script>
import numpad from '@/@core/components/NumPad.vue'
import Invoice from '@/@core/components/Invoice.vue'
import {
  BRow, BModal, BCol, BInputGroup, BInputGroupAppend,
  BFormInput, BCard, BCardBody, BLink, BImg, BButton, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Swal from 'sweetalert2';

export default {
  components: {
    numpad,
    BRow,
    BModal,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BPagination,
    Invoice,
  },
  data() {
    return {
      modalVisible: false,
      promoCode: '',
      validPromoCodePromotion: null,
    }
  },
  computed: {
    ...mapGetters('pos', ['cart', 'cartTotal', 'restockMode', 'currentRestockSupplier', 'offlineProducts','promoValidationMode']),
    ...mapGetters('recommenders', ['recommenders']),
  },
  methods: {
    ...mapActions('promotions', ['verifyPromoCode']),
    ...mapMutations('pos', ['SET_PROMO_VALIDATION_MODE']),

    openModal() {
      this.SET_PROMO_VALIDATION_MODE(true)
      this.$bvModal.show('validate-promo-code')
      this.modalVisible = true;
    },
    closeModal() {
      this.SET_PROMO_VALIDATION_MODE(false)
      this.modalVisible = false;
      this.promoCode = ''; // Clear the input field when closing the modal
    },
    validatePromoCode() {
      if (this.promoCode === '') {
        // Display an error message using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'El campo del código de descuento no puede estar vacío.'
        });
        return;
      }
      // Check if the promo code is valid and apply promotion
      this.verifyPromoCode({promoCode: this.promoCode, storeId: this.$route.params.store_id})
      .then(response => {
        if (response.status === 200) {
          // Display a success message using SweetAlert
          this.closeModal()
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'El código de descuento se ha aplicado correctamente.'
          });
        }
      })
      .catch(() => {
        // Display an error message using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'El código de descuento no es válido.'
        });
      });
      this.closeModal();
    }

  },
}
</script>

<style lang="scss" scoped>
.modal-body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
