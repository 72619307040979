<template>
  <div>
    <div
      v-b-modal.select-serviceType
      tabindex="-1"
      class="service-card supplier-card"
      :class="servicePaymentMode === true ? 'active-selection' : null"
      @click="SET_SERVICE_PAYMENT(true)"
    >
      <i class="fa fa-2x fa-cart-plus mb-1" />
      Pago de servicios y TAG
    </div>
    <!-- Show the services type -->
    <b-modal
      id="select-serviceType"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-title="Cancel"
      ok-only
      ok-variant="secondary"
      @ok="resetData('select-serviceType')"
    >
      <b-row class="d-flex justify-content-center w-100">
        <b-col
          v-for="(service, key) in servicePaymentsProducts"
          :key="`service-${key}`"
          sm="auto"
        >
          <b-button
            v-for="(typeService, serviceName) in service"
            :key="`service-${serviceName}`"
            v-b-modal.select-service-supplier
            :variant="serviceData === typeService ? 'info' :'primary'"
            class="m-1"
            block
            @click="serviceData = typeService"
          >
            {{ serviceName }}
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex flex-column">
            <h6
              v-if="emidaBBalance != null"
              class="text-left text-warning d-inline-block"
            >
              <div>
                Saldo para recargas:
              </div>
              $ {{ emidaBBalance | money }}
            </h6>
            <h6
              v-if="establishmentEmidaBBalance != null"
              class="text-left text-warning d-inline-block"
            >
              <div>
                Saldo para recargas compartido:
              </div>
              $ {{ establishmentEmidaBBalance | money }}
            </h6>
          </div>
          <b-button
            @click="resetData('select-serviceType')"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Show the suppliers for the service selected -->
    <b-modal
      id="select-service-supplier"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-only
      ok-title="Cancel"
      @ok="resetData('select-service-supplier')"
    >
      <b-row class="d-flex justify-content-center w-100">
        <b-col
          sm="auto"
        >
          <b-button
            v-for="(supplierService, supplierServiceName) in serviceData"
            :key="`service-${supplierServiceName}`"
            v-b-modal.select-option-service
            :variant="supplierServiceData === supplierService ? 'info' :'primary'"
            class="m-1"
            block
            @click="supplierServiceData = supplierService"
          >
            {{ supplierServiceName }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Show the options for the service supplier selected -->
    <b-modal
      v-if="supplierServiceData"
      id="select-option-service"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="fectchAndOpenModal()"
      @cancel="resetData('select-option-service')"
    >
      <p class="text-center text-warning">
        Este servicio acepta pagos {{ supplierServiceData[0].payment_type }}
      </p>
      <b-row class="d-flex justify-content-center w-100">
        <b-col>
          <b-button
            v-for="option in supplierServiceData"
            :key="`option-${option.id}`"
            :variant="selectedOption === option ? 'info' :'primary'"
            block
            class="my-1"
            size="lg"
            @click="selectedOption = option"
          >
            {{ option.carrier_name }} - {{ option.product_name }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="amount-modal"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="validationForm()"
      @cancel="resetData('amount-modal')"
    >
      <validation-observer
        ref="accountRules"
        tag="form"
      >
        <b-row v-if="selectedProduct" class="w-100">
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              ¿Cuántos créditos quieres agregar?
            </h5>
            <small class="text-warning">
              La cantidad mínima es de ${{ selectedProduct.amount_min | money }}
              y máxima de ${{ (Number(selectedProduct.amount_max) - Number(selectedProduct.product_u_fee)) | money }}
            </small>
          </b-col>
        </b-row>
        <b-row class="w-100"> 
          <b-col>
            <b-form-group
              label-for="v-ammount"
            >
              <validation-provider
                #default="{ errors }"
                name="Créditos"
                rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      :state="errors.length > 0 ? false:null"
                    >
                      <strong :class="errors.length > 0 ? 'text-danger' : null">$</strong>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    id="v-ammount"
                    v-model="emida_product.amount"
                    type="number"
                    :state="errors.length > 0 ? false : null|| (Number(emida_product.amount) <= (Number(selectedProduct.amount_max) - Number(selectedProduct.product_u_fee)) && Number(emida_product.amount) >= Number(selectedProduct.amount_min))"
                    placeholder="25"
                    size="lg"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>

    <b-modal
      id="charge-modal"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-row class="d-flex justify-content-center w-100">
        <b-col
          v-if="selectedProduct !== null"
          class="py-4"
        >
          <div class="display-1 text-success text-center">
            ${{ (Number(emida_product.amount)+ Number(selectedProduct.product_u_fee)).toFixed(2) }}
          </div>
          <h4 class="text-center">
            Cobra antes de continuar con la transacción
          </h4>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-danger"
            @click="resetData('charge-modal')"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            @click="$bvModal.show('select-account-and-finish-transaction')"
          >
            Efectuar compra
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-if="selectedProduct"
      id="select-account-and-finish-transaction"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <b-row class="w-100">
        <b-col>
          <b-tabs
            content-class="mt-3"
          >
            <b-tab
              v-for="(reference, key) in selectedProduct.references"
              :key="`reference-${key}`"
              :title="reference.reference_name"
            >
              <b-card-text>
                <p class="d-flex flex-column">
                  <span class="text-muted">
                    Información:
                  </span>
                  {{ reference.tool_tip }}
                </p>
                <validation-observer
                  #default="{ handleSubmit }"
                  :ref="`accountFormObserver-${key}`"
                >
                  <!-- Account Form -->
                  <b-form
                    @submit.prevent="handleSubmit(onSubmit(key))"
                    @reset.prevent="resetForm"
                  >

                    <validation-provider
                      #default="{ errors }"
                      name="account"
                      rules="required"
                    >
                      <b-form-group
                        :label="reference.reference_name"
                        label-for="account"
                      >
                        <b-input-group>
                          <b-input-group-prepend
                            is-text
                          >
                            <i class="fas fa-key" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="account"
                            v-model="emida_product.account_id"
                            type="number"
                            :state="errors.length > 0 ? false : null|| (String(emida_product.account_id).length >= Number(reference.length_min) && String(emida_product.account_id).length <= Number(reference.length_max))"
                            autocomplete="off"
                            trim
                          />
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      #default="{ errors }"
                      name="account"
                      rules="required"
                    >
                      <b-form-group
                        :label="reference.reference_name"
                        label-for="confirmation"
                      >
                        <!-- Validate phone number -->
                        <b-input-group class="mt-2">
                          <b-input-group-prepend
                            is-text
                          >
                            <i class="fas fa-key" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="confirmation"
                            v-model="registrationValidation"
                            type="number"
                            :state="errors.length > 0 ? false : null|| (String(registrationValidation).length >= Number(reference.length_min) && String(registrationValidation).length <= Number(reference.length_max) )"
                            autocomplete="off"
                            trim
                          />
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <b-row class="d-flex justify-content-between mt-2">
                      <b-col sm="auto">
                        <b-button
                          variant="secondary"
                          @click="resetData('select-account-and-finish-transaction')"
                        >
                          Cancelar
                        </b-button>
                      </b-col>
                      <b-col sm="auto">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-2"
                          type="submit"
                          :disabled="loading"
                        >
                          <b-spinner
                            v-if="loading"
                            small
                          />
                          Confirmar
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      loading: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      serviceData: null,
      supplierServiceData: null,
      selectedOption: null,
      selectedProduct: null,
      emida_product: {
        product_id: null,
        account_id: null,
        store_id: null,
        payment_type: 'cash',
        store_clerk_id: null,
        amount: 0,
        order_payments_attributes: [
          {
            payment_type: null,
            amount: null
          },
        ],
      },
      registrationValidation: null,
    }
  },
  computed: {
    ...mapGetters('stores', ['currentStore']),
    ...mapGetters('pos', ['servicePaymentMode']),
    ...mapGetters('emidaProducts', ['servicePaymentsProducts']),
    emidaBBalance() {
      let emidaBBalance = 0
      if (this.currentStore) {
        this.currentStore.accountings.forEach(account => {
          if (account.account_type === 'emida_b') {
            emidaBBalance = account.balance
          }
        })
        return emidaBBalance
      }
      return null
    },
    establishmentEmidaBBalance() {
      let establishmentEmidaBBalance = 0
      if (this.currentStore.establishment_accountings) {
        this.currentStore.establishment_accountings.forEach(account => {
          if (account.account_type === 'emida_b') {
            establishmentEmidaBBalance = account.balance
          }
        })
        return establishmentEmidaBBalance
      }
      return null
    },
  },
  methods: {
    ...mapMutations('pos', [
      'SET_SERVICE_PAYMENT',
      'SET_SUPPLIER',
    ]),
    ...mapActions('emidaProducts', ['fetchEmidaProduct', 'purchaseTypeB']),
    ...mapActions('printer', ['printEmidaInvoice']),
    fectchAndOpenModal() {
      this.fetchEmidaProduct(this.selectedOption.id)
        .then(response => {
          this.selectedProduct = response
          this.emida_product.amount = response.amount
          // eslint-disable-next-line no-unused-expressions
          Number(response.amount) === 0 ? this.$bvModal.show('amount-modal') : this.$bvModal.show('charge-modal')
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.$bvModal.show('charge-modal')
          } else {
            reject()
          }
        })
      })
    },
    resetData(id) {
      this.SET_SERVICE_PAYMENT(false)
      this.serviceData = null
      this.supplierServiceData = null
      this.selectedOption = null
      this.selectedProduct = null
      this.registrationValidation = null
      this.amountToPay = 0
      this.emida_product = {
        product_id: null,
        account_id: null,
        store_id: null,
        payment_type: 'cash',
        store_clerk_id: null,
        amount: 0,
        order_payments_attributes: [
          {
            payment_type: null,
            amount: null
          },
        ],
      }
      this.$bvModal.hide(id)
    },
    eliminarDiacriticos(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    onSubmit(reference) {
      this.$refs[`accountFormObserver-${reference}`][0].validate().then(success => {
        if (success) {
          if (this.emida_product.account_id === this.registrationValidation) {
            this.loading = true
            this.emida_product.product_id = this.selectedProduct.product_id
            this.emida_product.store_id = this.$route.params.store_id
            this.emida_product.store_clerk_id = this.userData.id
            this.emida_product.order_payments_attributes = [
              {
                payment_type: 'cash',
                amount: (Number(this.emida_product.amount)+ Number(this.selectedProduct.product_u_fee)).toString()
              },
            ],
            this.purchaseTypeB(this.emida_product)
              .then(response => {
                if (response.status === 202) {
                  this.$swal({
                    title: '¡Compra pendiente!',
                    text: response.data.messages[0],
                    icon: 'warning',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  if (JSON.parse(localStorage.getItem('print')) === true) {
                    const parsedRequest = JSON.parse(response.data.emida_response.request)
                    const orderToPrint = {
                      order_type: {
                        payment: 'cash',
                        type: 'sell',
                      },
                      store: this.eliminarDiacriticos(this.currentStore.name),
                      products: {
                        name: parsedRequest.productName,
                        important_info: response.data.emida_response.response_message,
                        autorization_code: response.data.emida_response.carrier_control_no,
                        reference: response.data.emida_response.pin,
                        unit_price: Number(this.emida_product.amount).toFixed(2),
                      },
                      total: Number(response.data.total),
                      date: Date.now(),

                    }
                    this.printEmidaInvoice({ ...orderToPrint })
                  }
                  this.$swal({
                    title: '¡Compra exitosa!',
                    text: 'Gracias.',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btnx btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
                this.loading = false
                this.resetData()
                this.$bvModal.hide('select-account-and-finish-transaction')
              })
              .catch(error => {
                this.loading = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.response.data.messages[0],
                  },
                })
                this.resetData()
                this.$bvModal.hide('select-account-and-finish-transaction')
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Revisa las referencias no coinciden',
              },
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.active-selection {
  background-color: $primary !important;
}

</style>
