<template>
  <div>
    <div
      tabindex="-1"
      class="service-card supplier-card"
      :class="supplierPaymentMode === true ? 'active' : null"
      @click="openModal()"
    >
      <i class="fas fa-2x fa-money-check-alt mb-1" />
      Pago a proveedor
    </div>
    <b-modal
      id="payment-supplier"
      @ok="completePaymentSupplier()"
      @cancel="SET_SUPPLIERPAYMENT_MODE(false)"
      @hidden="SET_SUPPLIERPAYMENT_MODE(false)"
    >
      <b-row class="d-flex justify-content-center">
        <!-- Pagination -->
        <b-col
          v-if="suppliers && suppliers.length > 0"
          cols="12"
          class="d-flex justify-content-end mb-1"
        >

          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="19"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="19"
              />
            </template>
          </b-pagination>
        </b-col>
        <b-col sm="auto">
          <b-button
            v-for="supplier in suppliers"
            :key="`supplier-${supplier.id}`"
            :variant="supplierData === supplier ? 'info' :'primary'"
            class="m-1"
            block
            @click="supplierData = supplier"
          >
            {{ supplier.name }}
          </b-button>
        </b-col>
        <b-col sm="auto">
          <validation-observer
            #default="{ handleSubmit }"
            ref="supplierFormObserver"
          >
            <h4
              v-if="supplierData"
              class="mb-2"
            >
              Información del pago a {{ supplierData.name }}
            </h4>
            <!-- Form -->
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <validation-provider
                #default="validationContext"
                name="amount"
                rules="required"
              >
                <b-form-group
                  label="Monto a pagar"
                  label-for="amount"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i class="fas fa-dollar-sign" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="amount"
                      v-model="supplier_payment.amount"
                      trim
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="payment_method"
                rules="required"
              >
                <b-form-group
                  label="Método de pago"
                  label-for="payment_method"
                >
                  <b-input-group>
                    <b-form-select
                      v-model="supplier_payment.payment_method"
                      :options="paymentMethodsOptions"
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="payment_reason"
              >
                <b-form-group
                  label="Razón de pago"
                  label-for="payment_reason"
                >
                  <b-input-group>
                    <b-form-textarea
                      id="payment_reason"
                      v-model="supplier_payment.payment_reason"
                      placeholder="Ingresa el motivo de pago..."
                      rows="3"
                      max-rows="6"
                    />
                  </b-input-group>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      supplierData: null,
      suppliers: null,
      pagination: null,
      supplier_payment: {
        store_supplier_id: null,
        payment_type: 'walleat',
        payment_method: null,
        payment_reason: null,
        amount: null,
      },
      paymentMethodsOptions: [
        { value: 'cash', text: 'Efectivo de la caja' },
        { value: 'cashNotInCashRegister', text: 'Efectivo no registrado en caja' },
        { value: 'onlinePayment', text: 'Tranferencia bancaria' },
        { value: 'bankCard', text: 'Pago con tarjeta' },
        { value: 'codi', text: 'Codi' },
      ],
    }
  },
  computed: {
    ...mapGetters('pos', ['supplierPaymentMode']),
  },
  created() {
    this.fetchStoreSuppliers({ by_store: this.$route.params.store_id })
      .then(response => {
        this.suppliers = response.data
        this.pagination = response.meta.pagination
      })
  },
  methods: {
    ...mapActions('suppliers', ['fetchStoreSuppliers', 'addSupplierPayment']),
    ...mapMutations('pos', [
      'SET_SUPPLIERPAYMENT_MODE',
    ]),
    openModal() {
      this.SET_SUPPLIERPAYMENT_MODE(true)
      this.$bvModal.show('payment-supplier')
    },
    handlePagination({ page }) {
      this.fetchStoreSuppliers({
        by_store: this.$route.params.store_id,
        meta: { pagination: { page } },
      })
        .then(response => {
          this.suppliers = response.data
          this.pagination = response.meta.pagination
        })
    },
    completePaymentSupplier() {
      this.supplier_payment.store_supplier_id = this.supplierData.id
      this.addSupplierPayment(this.supplier_payment)
        .then(() => {
          this.SET_SUPPLIERPAYMENT_MODE(false)
          this.$swal({
            title: '¡Pago a proveedor exitoso!',
            text: 'Gracias.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.SET_SUPPLIERPAYMENT_MODE(false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.data.messages.base[0],
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.active {
  background-color: $primary !important;
}

</style>
