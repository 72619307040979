<template>
  <div>
    <div
      v-b-modal.select-recharge-type
      tabindex="-1"
      class="service-card supplier-card"
      :class="airtimeRechargeMode === true ? 'active' : null"
      @click="SET_AIRTIME_RECHARGE(true)"
    >
      <i class="fa fa-2x fa-phone mb-1" />
      Recargas de tiempo aire
    </div>
    <!-- Show the services type -->
    <b-modal
      id="select-recharge-type"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-row>
        <b-col>
          <b-button
            v-for="(service, key) in otherProducts"
            :key="`service-${key}`"
            v-b-modal.select-recharge-supplier
            :variant="serviceData === service ? 'info' : 'primary'"
            block
            class="my-1"
            size="lg"
            @click="serviceData = service"
          >
            {{ key }}
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex flex-column">
            <h6 v-if="emidaABalance != null" class="text-left text-warning">
              <div>Saldo para recargas:</div>
              $ {{ emidaABalance | money }}
            </h6>
            <h6
              v-if="establishmentEmidaABalance != null"
              class="text-left text-warning"
            >
              <div>Saldo para recargas:</div>
              $ {{ establishmentEmidaABalance | money }}
            </h6>
          </div>
          <b-button @click="resetData('select-recharge-type')">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Show the suppliers for the service selected -->
    <b-modal
      id="select-recharge-supplier"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-title="Cancel"
      ok-only
      ok-variant="secondary"
      @ok="resetData('select-recharge-supplier')"
    >
      <b-row class="d-flex justify-content-center">
        <b-col>
          <b-button
            v-for="(supplierService, supplierServiceName) in serviceData"
            :key="`service-${supplierServiceName}`"
            v-b-modal.select-supplier-options
            :variant="
              supplierServiceData === supplierService ? 'info' : 'primary'
            "
            block
            class="my-1"
            size="lg"
            @click="supplierServiceData = supplierService"
          >
            {{ supplierServiceName }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Show the options for the service > supplier selected -->
    <b-modal
      id="select-supplier-options"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="fectchAndOpenModal()"
      @cancel="resetData('select-supplier-options')"
    >
      <b-row class="d-flex justify-content-center">
        <b-col>
          <b-button
            v-for="option in supplierServiceData"
            :key="`option-${option.id}`"
            :variant="selectedOption === option ? 'info' : 'primary'"
            block
            class="my-1"
            size="lg"
            @click="selectedOption = option"
          >
            {{ option.carrier_name }} - {{ option.product_name }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="payment-modal"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-row class="d-flex justify-content-center">
        <b-col v-if="selectedProduct !== null" class="py-4">
          <div class="display-1 text-success text-center">
            ${{ Number(selectedProduct.amount).toFixed(2) }}
          </div>
          <h4 class="text-center">
            Cobra antes de continuar con la transacción
          </h4>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button
            variant="outline-danger"
            @click="resetData('payment-modal')"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            @click="$bvModal.show('finish-transaction')"
          >
            Efectuar compra
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-if="selectedProduct"
      id="finish-transaction"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <b-row class="d-flex justify-content-center">
        <b-col sm="auto">
          <p class="d-flex flex-column">
            <span class="text-muted"> Información: </span>
            {{ selectedProduct.references[0].tool_tip }}
          </p>
          <validation-observer
            #default="{ handleSubmit }"
            ref="rechargeFormObserver"
          >
            <!-- Account Form -->
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <validation-provider
                #default="validationContext"
                name="account"
                rules="required|min:10"
              >
                <b-form-group
                  :label="selectedProduct.references[0].reference_name"
                  label-for="account"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i class="fas fa-mobile" />
                    </b-input-group-prepend>

                    <!-- Comentario -->
                    <b-form-input
                      id="account"
                      v-model="emida_product.account_id"
                      :state="getValidationState(validationContext)"
                      autocomplete="off"
                      trim
                      :formatter="formatter"
                      @input="phoneSizeValidation()"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="account"
                rules="required|min:10"
              >
                <b-form-group
                  :label="selectedProduct.references[0].reference_name"
                  label-for="confirmation"
                >
                  <!-- Validate phone number -->
                  <b-input-group class="mt-2">
                    <b-input-group-prepend is-text>
                      <i class="fas fa-mobile" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="confirmation"
                      v-model="phoneValidation"
                      :state="getValidationState(validationContext)"
                      autocomplete="off"
                      trim
                      :formatter="formatter"
                      @input="phoneSizeValidation()"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-row class="d-flex justify-content-between mt-2">
                <b-col sm="auto">
                  <b-button
                    variant="secondary"
                    @click="resetData('finish-transaction')"
                  >
                    Cancelar
                  </b-button>
                </b-col>
                <b-col sm="auto">
                  <b-button
                    v-if="toggleDisable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    :disabled="loading"
                  >
                    <b-spinner v-if="loading" small />
                    Confirmar
                  </b-button>
                  <b-button v-if="!toggleDisable" class="mr-2">
                    Confirmar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup({ emit }) {
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      emit,
      required,
    };
  },
  data() {
    return {
      loading: false,
      toggleDisable: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      serviceData: null,
      supplierServiceData: null,
      selectedOption: null,
      selectedProduct: null,
      phoneValidation: null,
      emida_product: {
        product_id: null,
        account_id: null,
        store_id: null,
        payment_type: "cash",
        store_clerk_id: null,
        order_payments_attributes: [
          {
            payment_type: null,
            amount: null,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("stores", ["currentStore"]),
    ...mapGetters("pos", ["airtimeRechargeMode"]),
    ...mapGetters("emidaProducts", ["otherProducts"]),
    emidaABalance() {
      let emidaABalance = 0;
      if (this.currentStore) {
        this.currentStore.accountings.forEach((account) => {
          if (account.account_type === "emida_a") {
            emidaABalance = account.balance;
          }
        });
        return emidaABalance;
      }
      return null;
    },
    establishmentEmidaABalance() {
      let establishmentEmidaABalance = 0;
      if (this.currentStore.establishment_accountings) {
        this.currentStore.establishment_accountings.forEach((account) => {
          if (account.account_type === "emida_a") {
            establishmentEmidaABalance = account.balance;
          }
        });
        return establishmentEmidaABalance;
      }
      return null;
    },
  },
  mounted() {
    this.toggleDisable = false;
    this.emidaConnection = new WebSocket(
      `${process.env.VUE_APP_WSS_URL}/cable?token=${this.userData.token}`
    );
    sessionStorage.setItem(
      "wsConnectionEmida",
      JSON.stringify(this.emidaConnection)
    );

    this.emidaConnection.onmessage = (event) => {
      const messagex = JSON.parse(event.data);
      const device = this.getMobileOperatingSystem();
      if (typeof messagex.message === "object") {
        this.$swal({
          title: messagex.message.title,
          text: messagex.message.content_message,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        if (messagex.message.json_message !== null) {
          const parseOrder = messagex.message.json_message;
          if (JSON.parse(localStorage.getItem("print")) === true) {
            const parsedRequest = JSON.parse(parseOrder.emida_response.request);
            const objectToPrint = {
              order_type: {
                payment: "cash",
                type: "sell",
              },
              store: this.eliminarDiacriticos(this.currentStore.name),
              products: {
                name: parsedRequest.productName,
                important_info: parseOrder.emida_response.response_message,
                autorization_code: parseOrder.emida_response.carrier_control_no,
                reference: parseOrder.emida_response.pin,
                unit_price: Number(parseOrder.total).toFixed(2),
              },
              total: Number(parseOrder.total).toFixed(2),
              date: Date.now(),
            };
            console.log(device);
            if (device === "Android") {
              // Serialize the orderToPrint object to a JSON string
              const objectToPrintJson = JSON.stringify(objectToPrint);

              // URL-encode the JSON string to include it in the intent URL
              const objectToPrintEncoded =
                encodeURIComponent(objectToPrintJson);

              // Define the second intent URL with the order included as a query parameter
              const intentUrl = `intent://print?directLink=true#Intent;scheme=extprint;package=com.compitienda.compitienda_printer;S.XXXX=${objectToPrintEncoded};end`;

              // Try to open the intent URL
              window.location.href = intentUrl;
            } else {
              this.printEmidaInvoice({ ...objectToPrint });
            }
          }
        } else if (messagex.message.json_message === null) {
          if (JSON.parse(localStorage.getItem("print")) === true) {
            const objectToPrint = {
              store: this.eliminarDiacriticos(this.currentStore.name),
              date: Date.now(),
              order_type: {
                payment: "cash",
                type: "error",
              },
              message: messagex.message.content_message,
            };
            console.log(device);
            if (device === "Android") {
              // Serialize the orderToPrint object to a JSON string
              const objectToPrintJson = JSON.stringify(objectToPrint);

              // URL-encode the JSON string to include it in the intent URL
              const objectToPrintEncoded =
                encodeURIComponent(objectToPrintJson);

              // Define the second intent URL with the order included as a query parameter
              const intentUrl = `intent://print?directLink=true#Intent;scheme=extprint;package=com.compitienda.compitienda_printer;S.XXXX=${objectToPrintEncoded};end`;

              // Try to open the intent URL
              window.location.href = intentUrl;
            } else {
              this.printEmidaErrorTransaction({ ...objectToPrint });
            }
          }
        }
      }
    };

    this.emidaConnection.onopen = () => {
      // eslint-disable-next-line
      console.log("Successfully connected to the echo websocket server...");

      // eslint-disable-next-line
      this.emidaConnection.send(
        JSON.stringify({
          command: "subscribe",
          identifier: '{"channel":"ApplicationCable::MyChannel"}',
        })
      );
    };
  },
  destroyed() {
    /**
     * When the view has change destroyed the webSocket connection
     * */
    this.emidaConnection.close();
  },
  methods: {
    ...mapMutations("pos", ["pushProductToCart", "SET_AIRTIME_RECHARGE"]),
    ...mapActions("emidaProducts", ["fetchEmidaProduct", "purchaseTypeA"]),
    ...mapActions("printer", [
      "printEmidaInvoice",
      "printEmidaErrorTransaction",
    ]),
    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "unknown";
    },
    fectchAndOpenModal() {
      this.fetchEmidaProduct(this.selectedOption.id).then((response) => {
        this.selectedProduct = response;
        this.$bvModal.show("payment-modal");
      });
    },
    formatter(value) {
      const temp = value.match(/[0-9]+/g).join("");
      const temp2 = temp.replace(/[a-zA-Z]/g, "");
      return temp2.substring(0, 10);
    },
    phoneSizeValidation() {
      if (
        this.emida_product.account_id.length === 10 &&
        this.phoneValidation.length === 10
      ) {
        if (this.emida_product.account_id === this.phoneValidation) {
          this.toggleDisable = true;
        }
      } else {
        this.toggleDisable = false;
      }
    },
    eliminarDiacriticos(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    resetData(id) {
      this.SET_AIRTIME_RECHARGE(false);
      this.serviceData = null;
      this.supplierServiceData = null;
      this.selectedOption = null;
      this.selectedProduct = null;
      this.phoneValidation = null;
      this.emida_product = {
        product_id: null,
        account_id: null,
        store_id: null,
        store_clerk_id: null,
        order_payments_attributes: [
          {
            payment_type: null,
            amount: null,
          },
        ],
      };
      this.$bvModal.hide(id);
    },
    onSubmit() {
      this.$refs.rechargeFormObserver.validate().then((success) => {
        if (success) {
          if (this.emida_product.account_id === this.phoneValidation) {
            this.loading = true;
            this.emida_product.product_id = this.selectedProduct.product_id;
            this.emida_product.store_id = this.$route.params.store_id;
            this.emida_product.store_clerk_id = this.userData.id;
            (this.emida_product.order_payments_attributes = [
              {
                payment_type: "cash",
                amount: this.selectedProduct.amount,
              },
            ]),
              this.purchaseTypeA(this.emida_product)
                .then((response) => {
                  if (response.status !== 202) {
                    if (JSON.parse(localStorage.getItem("print")) === true) {
                      const parsedRequest = JSON.parse(
                        response.data.emida_response.request
                      );
                      const orderToPrint = {
                        order_type: {
                          payment: "cash",
                          type: "sell",
                        },
                        store: this.eliminarDiacriticos(this.currentStore.name),
                        products: {
                          name: parsedRequest.productName,
                          important_info:
                            response.data.emida_response.response_message,
                          autorization_code:
                            response.data.emida_response.carrier_control_no,
                          reference: response.data.emida_response.pin,
                          unit_price: Number(
                            this.selectedOption.amount
                          ).toFixed(2),
                        },
                        total: Number(this.selectedOption.amount),
                        date: Date.now(),
                      };
                      this.printEmidaInvoice({ ...orderToPrint });
                    }
                    this.$swal({
                      title: "¡Compra exitosa!",
                      text: "Gracias.",
                      icon: "success",
                      customClass: {
                        confirmButton: "btnx btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  } else {
                    this.$swal({
                      title: "¡Compra pendiente!",
                      text: response.data.messages[0],
                      icon: "warning",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  }
                  this.loading = false;
                  this.resetData();
                  this.$bvModal.hide("finish-transaction");
                })
                .catch((error) => {
                  this.loading = false;
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "Error",
                      icon: "CoffeeIcon",
                      variant: "danger",
                      text: error.response.data.messages[0],
                    },
                  });
                  this.resetData();
                  this.$bvModal.hide("finish-transaction");
                });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                icon: "CoffeeIcon",
                variant: "danger",
                text: "Revisa los números telefónicos no coinciden",
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.active {
  background-color: $primary !important;
}
</style>
