<template>
  <div>
    <b-button
      tabindex="3"
      variant="outline-danger"
      size="lg"
      block
      @click="updateProductsInventory()"
    >
      Actualizar inventario
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('pos', ['cart', 'cartTotal', 'restockMode', 'currentRestockSupplier']),
  },
  methods: {
    ...mapActions('storeProducts', ['editStoreProduct']),
    ...mapActions('pos', ['emptyCart']),
    ...mapMutations('pos', ['SET_PRODUCT_INVENTORY_MODE']),
    updateProductsInventory() {
      this.$bvModal.msgBoxConfirm('Por favor confirma que deseas actualizar el inventario.', {
        title: 'Inventarido',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            const editProductsInCart = products => new Promise(resolve => {
              products.forEach(product => {
                this.editStoreProduct(product)
              })
              resolve()
            })
            const requests = []
            this.cart.forEach(product => {
              const productUpdated = {
                id: product.id,
                inventory: product.units,
                has_inventory: true,
              }
              requests.push(productUpdated)
            })
            editProductsInCart(requests)
              .then(() => {
                const totalProducts = requests.length
                this.$swal({
                  title: `Se actualizaron ${totalProducts} productos!`,
                  text: 'Gracias.',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.emptyCart()
                this.SET_PRODUCT_INVENTORY_MODE(false)
              })
          } else {
            this.isActive = false
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
