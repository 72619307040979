<template>
  <div class="w-100 h-100">
    <b-button
      v-b-modal.nfc-modal
      tabindex="1"
      variant="shadow"
      size="md"
      class="w-100 h-100 payment-button"
    >
      <b-img
        :src="require('@/assets/images/logo/walleat.png')"
        width="120"
        class="logo"
      />
    </b-button>
    <b-modal id="nfc-modal" hide-footer>
      <b-row class="mb-2" v-if="!enc && !walleatName">
        <b-col class="text-center">
          <h1 class="">Escanear Walleat</h1>
          <p>acerca el Walleat a tu dispositivo con NFC</p>
          <div>
            <b-img
              src="https://thumbs.gfycat.com/LawfulWavyIlsamochadegu-small.gif"
              width="200"
              rounded
            />
          </div>
        </b-col>
      </b-row>
      <NFC />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import NFC from "@/views/e-commerce/e-commerce-checkout/raspberryNfcService.vue";

export default {
  components: {
    NFC,
  },
  data() {
    return {
      walleatName: null,
    };
  },
  computed: {
    ...mapGetters("pos", [
      "cart",
      "cartTotal",
      "restockMode",
      "currentRestockSupplier",
    ]),
    ...mapGetters("nfcService", ["enc"]),
  },
  methods: {
    ...mapActions("orders", ["addOrder"]),
    ...mapActions("printer", ["print"]),
    ...mapActions("pos", ["emptyCart"]),
    ...mapMutations("pos", ["SET_RESTOCK_MODE", "SET_SUPPLIER"]),
  },
};
</script>

<style lang="scss" scoped>
.payment-button {
  font-size: 1.2rem !important;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 !important;
  font-size: 1rem !important;

  @media (min-width: 480px) {
    font-size: 1.2rem !important;

    img {
      width: 100px;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.5rem !important;
  }
}

.logo {
  filter: invert(1);
  transition: filter 0.3s ease;
}

.dark-layout {
  .logo {
    filter: none;
  }
}
</style>
