<template>
  <b-modal
    id="bank-deposit-modal"
    title="Transferencia bancaria"
    size="lg"
    no-stacking
    no-close-on-esc
    no-close-on-backdrop
    :hide-header="true"
    hide-footer
  >
    <validation-observer
      ref="celRules"
      v-slot="{ invalid }"
    >
    <b-form
      @submit.prevent="handleCompleteOrder"
    >
      <b-form-group
        id="fieldset-1"
        label="Ingresa numero de celular registrado"
        label-for="cel-input"
        label-size="lg"
      >  
        <validation-provider
          #default="{ errors, valid }"
          name="Celular"
          rules="required|digits:10"
          vid="cel"
        >
          <b-form-input 
            id="cel-input"
            v-model="order.cel_number"
            placeholder="Celular a 10 dígitos"
            size="lg"
            type="number"
            :state="errors.length > 0 ? false: (valid ? true : null)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="fieldset-1"
        label="Confirma el número de celular"
        label-for="cel-input-confirm"
        label-size="lg"
      >  
        <validation-provider
          #default="{ errors, valid }"
          name="Celular"
          rules="confirmed:cel|digits:10|required"
        >
          <b-form-input 
            id="cel-input-confirm"
            v-model="confirmCel"
            placeholder="Celular a 10 dígitos"
            size="lg"
            type="number"
            :state="errors.length > 0 ? false: (valid ? true : null)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <div class="d-flex justify-content-end mt-2">
        <b-button
          :disabled="loading"
          variant="outline-danger"
          @click="closeModal()"
          class="mr-1"
          size="lg"
        >
          Atras
        </b-button>
        <b-button
          :disabled="loading || invalid"
          :variant="invalid ? 'outline-danger' : 'success'"
          type="submit"
          class="bold"
          size="lg"
        >
          <b-spinner
            v-if="loading"
            small
          />
          Aceptar
        </b-button>
      </div>
    </b-form>
    </validation-observer>
  </b-modal>
</template>


<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapMutations } from 'vuex'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data(){
    return {
      confirmCel: '',
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    completeOrder: {
      type: Function,
      required: true,
    },
    order: {
      type: Object,
      validator: (p) => {
        return ['object'].indexOf(typeof p) !== -1 || p === null;
      },
    },
  },
  methods: {
    ...mapMutations('pos', ['SET_BANK_DEPOSIT_MODE']),
    handleCompleteOrder(){
      this.$refs.celRules.validate().then(success => {
        if(success){
          this.completeOrder('bank_deposit')
        }
      })
    },
    closeModal() {
      this.$bvModal.hide('bank-deposit-modal')
      this.$bvModal.show('pos-preview-modal')
      this.order.cel_number = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.button-group{
  display: flex;
  gap: 10px;
  padding: 30px 0;
}

.bold{
  font-weight: bold;
  font-weight: 900;
}

</style>


