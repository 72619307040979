<template>
  <section
    class="mb-50 pb-2 h-100"
  >
    <b-row class="w-10 mr-0 ml-0">
      <b-col
        v-if="favoriteProducts.length > 0"
        cols="12"
        class="d-flex justify-content-center mb-1"
      >
        <b-pagination
          v-model="pagination.page"
          :total-rows="pagination.total_objects"
          :per-page="pagination.per_page"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="(value)=>{handlePagination({ page: value })}"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="19"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="19"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row class="w-100 mr-0 ml-0 grid overflow-auto">
      <!-- Products grid -->
      <b-col class="overflow-auto h-100">
        <div class="container fav-grid">
          <b-card
            v-for="(product, n) in favoriteProducts"
            :key="`item${n}`"
            class="fav-product"
            @click="addProductAndClearQuery(product)"
            no-body
          >
            <div class="text-center mb-50" v-if="product && product.product_attributes">
              <img
                :src="product.product_attributes.logo"
                alt=""
                width="80px"
              >
            </div>
            <div class="name" v-if="product">
              <p>
                {{ product.product_attributes.name }}
              </p>
              <b-badge 
                pill
                variant="primary"
                v-if="product.store_product_variant_attributes.length"
                v-for="(variant, n) in product.store_product_variant_attributes"
                :key="`variant${n}`"
              >
                {{ variant.variant_option_attributes.option_name }}
              </b-badge>
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import WeightModal from '@/views/pos/WeightModal.vue'

export default {
  components: {
    WeightModal,
  },
  data() {
    return {
      pagination: {},
    }
  },
  computed: {
    ...mapGetters('storeProducts', ['favoriteProducts']),
    ...mapGetters('weight', ['weight']),
    ...mapGetters('pos', ['restockMode', 'productInventoryMode']),
  },
  mounted() {
    this.fetchFavoriteProducts(this.$route.params.store_id)
      // eslint-disable-next-line no-return-assign
      .then(response => this.pagination = response.meta.pagination)
  },
  methods: {
    ...mapActions('storeProducts', ['fetchFavoriteProducts']),
    ...mapActions('storeProducts', ['editStoreProduct']),
    ...mapActions('pos', ['addProductToCart', 'getPromotionTotal']),
    ...mapActions('weight', ['getWeight']),
    ...mapMutations('pos', [
      'setProductQuantity',
      'setProductWeight']),
    handlePagination({ page }) {
      this.fetchFavoriteProducts({
        store_id: this.$route.params.store_id,
        page,
      })
        .then(response => {
          this.pagination = response.meta.pagination
        })
    },
    addProductAndClearQuery(product) {
      // eslint-disable-next-line
      const audio = new Audio(require('@/assets/sounds/Beep2.wav'))
      if ((this.restockMode === true && this.product.has_inventory === true) || this.restockMode === false) {
        audio.play()
        if (navigator.vibrate) {
          navigator.vibrate(200)
        }
        this.addProductToCart({ data: [{ ...product }] })
          .then(() => {
            if (product.product_attributes.is_weighted) {
              this.getWeight()
                .then(() => {
                  // eslint-disable-next-line
                  const audio = new Audio(require('@/assets/sounds/Pop.wav'))
                  audio.play()
                  setTimeout(() => {
                    const x = this.weight.replace(/[^\d.-]/g, '')
                    this.setProductWeight({
                      cartItem: product,
                      units: Number(x),
                    })
                    this.getPromotionTotal({ data: [{ ...product }]})
                  }, 250)
                })
                .catch(() => {
                  this.$bvModal.show(`bascula-${product.id}`)
                })
            } else if (this.productInventoryMode === true) {
              this.$bvModal.show(`product-modal-${product.id}`)
            }
          })
        this.$emit('productAddedToCart')
      } else {
        this.$bvModal.msgBoxConfirm('El producto seleccionado no maneja invetario, ¿desea modificarlo?', {
          title: 'Mensaje de confirmación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SÍ',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
          .then(value => {
            if (value === true) {
              const productEdited = {
                ...product,
                has_inventory: true,
              }
              this.editStoreProduct(productEdited)
                .then(() => {
                  audio.play()
                  if (navigator.vibrate) {
                    navigator.vibrate(200)
                  }

                  this.addProductToCart({ data: [{ ...product }] })
                    .then(() => {
                      if (product.product_attributes.is_weighted) {
                        this.getWeight()
                          .then(() => {
                            // eslint-disable-next-line
                  const audio = new Audio(require('@/assets/sounds/Pop.wav'))
                            audio.play()
                            setTimeout(() => {
                              const x = this.weight.replace(/[^\d.-]/g, '')
                              this.setProductWeight({
                                cartItem: product,
                                units: Number(x),
                              })
                            }, 250)
                          })
                          .catch(() => {
                            this.$bvModal.show(`bascula-${product.id}`)
                          })
                      } else if (this.productInventoryMode === true) {
                        this.$bvModal.show(`product-modal-${product.id}`)
                      }
                    })
                  this.$emit('productAddedToCart')
                })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fav-product {
  border: 1px solid rgba(white, .25);
  border-radius: 6px;
  width: 130px;
  padding: 0.5rem;
  cursor: pointer;
  .name {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }
}

.fav-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: .5rem;
}
</style>
