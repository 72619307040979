<template>
  <b-modal
    id="pos-preview-modal"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    centered
  >
    <div class="d-flex justify-content-center button-group">
      <b-button
        size="lg"
        variant="primary"
        @click="newAccountModal()"
      >
        Cuenta nueva
      </b-button>
      <b-button
        @click="existingAccountModal()"
        variant="info"
        size="lg"
      > 
        Cuenta existente
      </b-button>
    </div>

    <template #modal-footer>
      <b-button
        variant="outline-danger"
        @click="closePreview()"
      >
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import { mapMutations, mapGetters } from 'vuex'

export default {
  data() {
    return {

    }
  },
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('pos', ['codiPaymentMode', 'bankDepositMode']),
  },
  methods: {
    ...mapMutations('pos', ['SET_BANK_DEPOSIT_MODE', 'SET_CODI_PAYMENT_MODE']),
    closePreview() {
      this.$bvModal.hide('pos-preview-modal')
      this.SET_BANK_DEPOSIT_MODE(false)
      this.SET_CODI_PAYMENT_MODE(false)
    },
    existingAccountModal(){
      this.$bvModal.hide('pos-preview-modal')
      this.$bvModal.hide('payments-modal')
      if(this.bankDepositMode){
        this.$bvModal.show('bank-deposit-modal')
      } else if(this.codiPaymentMode){
        this.$bvModal.show('complete-codi-payment')
      }
    },
    newAccountModal(){
      this.$bvModal.hide('pos-preview-modal')
      this.$bvModal.hide('payments-modal')
      this.$bvModal.show('pos-new-customer-modal')
    },
  },
}

</script>

<style lang="scss" scoped>
.button-group{
  display: flex;
  gap: 10px;
  padding: 30px 0;
}

</style>
