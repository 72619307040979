<template>
  <div class="cart cart-responsive p-1" tabindex="-1">
    <transition-group name="list" tag="div">
      <div
        v-for="product in cartByReference"
        :key="product.id + product.reference"
        v-b-modal.product-modal
        class="mb-50 p-1 product list-item shadow overflow-auto"
        no-body
        @click="handleBookProductModal(product)"
      >
        <div class="d-flex justify-content-center align-items-center">
          <b-img
            :src="product.product_attributes.logo"
            width="70"
            rounded
            class="logo"
          />
        </div>
        <div class="px-1 d-flex flex-column">
          <div class="product-name mb-0 font-weight-bold">
            {{ product.product_attributes.name }}
          </div>
          <div class="font-weight-bolder">
            {{ product.product_attributes.variant }}
          </div>
          <div
            class="font-weight-bolder"
            v-if="
              product.store_product_variant_attributes.length &&
              product.store_product_variant_attributes[0]
                .variant_option_attributes
            "
          >
            {{
              product.store_product_variant_attributes[0]
                .variant_option_attributes.option_name
            }}
          </div>
          <div v-if="isStoreValid">
            <div>Para: {{ JSON.parse(product.reference)["name"] }}</div>
            <div>
              {{ JSON.parse(product.reference)["email"] }}
            </div>
            <div>+52 {{ JSON.parse(product.reference)["contact"] }}</div>
          </div>
          <div class="text-info product-quantity">
            x
            {{
              product.product_attributes.is_weighted
                ? `${product.units} kg`
                : product.units
            }}
          </div>
        </div>
        <div
          v-if="restockMode === false"
          class="h3 d-flex align-items-center pr-1 text-primary product-price"
        >
          ${{ product.product_total | money }}
        </div>
        <div
          v-if="restockMode === true"
          class="h3 d-flex align-items-center pr-1 text-primary product-price"
        >
          ${{ (product.unit_cost * product.units) | money }}
        </div>
        <div
          class="h3 d-flex align-items-center pr-1 text-danger product-price"
        >
          <b-button
            variant="outline-danger"
            class="m-10"
            @click.stop="deleteProductFromCart(product)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </div>
        <product-modal
          :product="product"
          v-if="
            (currentStore.store_type &&
              currentStore.store_type !== 'book_store') ||
            currentStore.store_type !== 'corporate_education'
          "
        />
        <book-product-modal
          :product="product"
          :handleIncreaseReferenceQuantity="handleIncreaseReferenceQuantity"
          v-if="
            (currentStore.store_type &&
              currentStore.store_type === 'book_store') ||
            currentStore.store_type === 'corporate_education'
          "
        />
      </div>
    </transition-group>
    <div v-if="cart.length > 0" class="product-count text-center text-muted">
      <div class="mt-2 mb-1 h4">
        {{ cartTotalProducts }} producto<span v-if="cart.length > 1">s</span>
      </div>
      <b-button
        tabindex="-1"
        class="text-muted"
        variant="outline-secondary"
        size="sm"
        @click="warningBeforeEmptyCart()"
      >
        <i class="fas fa-trash mr-1" /> Borrar
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import ProductModal from "@/views/pos/ProductModal.vue";
import BookProductModal from "@core/components/WallEateCommerce/BookProductModal.vue";

export default {
  components: {
    ProductModal,
    BookProductModal,
  },
  data() {
    return {
      selectedProduct: null,
      byReferenceTypes: ["book_store", "corporate_education"],
      isStoreValid: false,
    };
  },
  props: {
    products: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  beforeMount() {
    if (this.currentStore && this.currentStore.store_type) {
      this.isStoreValid = this.byReferenceTypes.includes(
        this.currentStore.store_type
      );
    }
  },
  computed: {
    ...mapGetters("pos", [
      "cart",
      "cartTotalProducts",
      "productTotal",
      "restockMode",
      "booksWithReference",
    ]),
    ...mapGetters("stores", ["currentStore"]),

    cartByReference() {
      if (this.isStoreValid) {
        return this.calculateCartByReference();
      } else {
        return this.cart;
      }
    },
    isMobile() {
      return this.screenWidth < 770 && this.cart.length > 0;
    },
  },
  methods: {
    ...mapMutations("pos", [
      "emptyCart",
      "deleteProductFromCarts",
      "setReferenceToBookWithProductId",
    ]),
    ...mapActions("pos", ["addProductToCart"]),

    deleteProductFromCart(product) {
      this.$swal({
        title: "Confirma...",
        icon: "warning",
        text: "¿Estás seguro que deseas eliminar el producto del carrito?",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "seee, borrarlo!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteNcloseModal(product);
        }
      });
    },

    warningBeforeEmptyCart() {
      this.$swal({
        title: "Confirma...",
        icon: "warning",
        text: "¿Estás seguro que deseas eliminar los productos del carrito?...esta acción no se puede revertir",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "seee, borrarlo!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.emptyCart();
        }
      });
    },
    deleteNcloseModal(product) {
      this.deleteProductFromCarts(product);
    },

    handleIncreaseReferenceQuantity(product) {
      this.setReferenceToBookWithProductId(product);
      const selectedProduct = this.products.find((p) => p.id === product.id);
      this.addProductToCart({ data: [{ ...selectedProduct }] });
    },

    handleBookProductModal(product) {
      if (this.isStoreValid) {
        this.$bvModal.show(
          `book-product-modal-${product.id}-${product.reference}`
        );
      } else {
        this.$bvModal.show(`product-modal-${product.id}`);
      }
    },
    calculateCartByReference() {
      return this.booksWithReference
        .map((book) => {
          let reference = book.reference;
          let productIds = Object.keys(book.storeProducts);

          return productIds.map((productId) => {
            const cartProduct = this.cart.find(
              (product) =>
                product.id === productId &&
                product.references.includes(reference)
            );

            return {
              ...cartProduct,
              reference: reference,
              units: book.storeProducts[productId],
              product_total:
                cartProduct.unit_price * book.storeProducts[productId],
            };
          });
        })
        .flat();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";
.dark-layout {
  .cart {
    background-color: #12182c !important;

    .product {
      background: #161d31 !important;

      &:hover {
        background: #1b243d !important;
      }

      .product-name {
        color: #fff !important;
      }
    }
  }
}

.cart {
  background-color: $body-bg;
  overflow-y: auto;
  height: 100%;
  .product {
    border-radius: 5px;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    transition: transform 0.25s ease;
    &:hover {
      background: #f8f9fa;
    }
    .product-name {
      font-size: 18px;
      color: $black;
    }
  }
  .product-count {
    margin-bottom: 30px !important;
  }

  @media (max-width: 770px) {
    .product {
      .product-name {
        font-size: 1rem;
      }

      .product-price {
        font-size: 1.2rem;
      }

      .product-quantity {
        font-size: 1rem;
      }
    }
  }
}
.list-item {
  display: block;
}
.list-enter-active,
.list-leave-active {
  transition: all 5s ease;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translatex(800px);
}

.logo {
  filter: invert(0.1);
  transition: filter 0.3s ease;
}

@media (prefers-color-scheme: dark) {
  .logo {
    filter: none;
  }
}
</style>
