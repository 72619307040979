<template>
  <div class="overflow-auto position-relative">
    <!-- SEARCHBAR -->
    <div
      class="d-flex align-items-center mb-2 searchbar sticky top-0 shadow-sm"
    >
      <i class="fas fa-search fa-2x" />
      <b-input
        id="skuScanner"
        v-model="searchQuery"
        tabindex="0"
        autocomplete="off"
        placeholder="Buscar por nombre o codigo de barras"
        size="lg"
        class="bg-transparent border-0 shadow-none"
      />
      <b-button
        v-if="searchBtn"
        variant="primary"
        class="mr-1"
        @click="lunrSearch(searchQuery)"
      >
        Buscar
      </b-button>
      <b-button
        v-if="searchQuery != null && searchQuery != ''"
        tabindex="-1"
        variant="danger"
        @click="clearResultsAndSearchQuery()"
      >
        <i class="fas fa-times" />
      </b-button>
    </div>
    <div v-if="restockMode">
      <h2 class="text-center text-warning">
        👆 Toca aqui para Buscar productos 👆
      </h2>
    </div>

    <!-- RESULTS -->
    <div v-if="searchQuery != null && searchQuery != ''">
      <product-card
        v-for="(item, n) in results.filter((x) => x.score > 0)"
        :key="n"
        :product="offlineProducts[item.ref]"
        @productAddedToCart="searchQuery = ''"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import productCard from "@/views/test/ProductCard.vue";
import _ from "underscore";

export default {
  components: {
    productCard,
  },
  data() {
    return {
      results: [],
      searchQuery: "",
      idx: null,
      allItems: [],
      searchBtn: false,
    };
  },
  computed: {
    ...mapGetters("pos", [
      "lunrCtx",
      "offlineProducts",
      "restockMode",
      "cashWithdrawalMode",
      "supplierPaymentMode",
      "airtimeRechargeMode",
      "productInventoryMode",
      "servicePaymentMode",
      "loanPaymentMode",
    ]),
  },
  watch: {
    // eslint-disable-next-line
    searchQuery: _.debounce(async function (newValue) {
      if (/^\d*$/.test(newValue) && newValue && newValue !== "") {
        if (newValue.length > 4) {
          this.searchBtn = false;
          const sku = String(Number(newValue)).slice(0, -1);
          this.lunrSearch(sku);
        } else {
          this.searchBtn = true;
        }
      } else if (newValue && newValue !== "") {
        this.results = this.lunrCtx.search(`${newValue}~1`);
      }
    }, 250),
  },
  created() {
    window.addEventListener("keypress", this.setFocusElement);
  },
  beforeUpdate() {
    window.addEventListener("keypress", this.setFocusElement);
  },
  destroyed() {
    window.removeEventListener("keypress", this.setFocusElement);
  },
  methods: {
    ...mapActions("pos", ["addProductToCart"]),
    clearResultsAndSearchQuery() {
      this.searchQuery = "";
      this.results = [];
      this.searchBtn = false;
    },
    setFocusElement() {
      document.getElementById("skuScanner").focus();
    },
    async lunrSearch(sku) {
      const results = await this.lunrCtx.search(`sku:${sku}*`);
      if (
        results.length === 1 ||
        (results.length === 1 && this.productInventoryMode === true)
      ) {
        // eslint-disable-next-line
        const audio = new Audio(require("@/assets/sounds/Beep2.wav"));
        audio.play();
        if (navigator.vibrate) {
          navigator.vibrate(200);
        }
        this.searchBtn = false;
        this.addProductToCart({
          data: [{ ...this.offlineProducts[results[0].ref] }],
        }).then(() => {
          if (this.productInventoryMode) {
            this.$bvModal.show(
              `product-modal-${this.offlineProducts[results[0].ref].id}`
            );
          }
        });
        this.searchQuery = null;
        this.results = [];
      } else {
        this.results = results;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/colors/palette-variables.scss";
.searchbar {
  background-color: $white;
  padding: 1rem;
  width: 100%;
  margin-right: -1rem !important;
  border-radius: 20px 0 0 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 60px;
}
</style>
