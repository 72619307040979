<template>
  <div class="w-100 h-100">
    <b-button
      tabindex="3"
      variant="shadow"
      class="h-100 w-100 payment-button"
      @click="openModal()"
    >
      <b-img :src="require('@/assets/images/logo/CASH.svg')" width="80" />
      <span class="py-2 block">Efectivo</span>
    </b-button>
    <numpad is-cash-payment @ok="completeSaleWithCash($event)" />
    <b-modal id="invoice-modal" title="Venta exitosa" size="xl" ok-only>
      <invoice :order-id="orderIdForInvoice" />
    </b-modal>
    <b-modal
      id="sale-complete-modal"
      title="Venta exitosa"
      class="modal-content"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :hide-header="true"
    >
      <div class="modal-body d-flex align-items-center justify-content-center">
        <b-col>
          <div style="color: orange" class="d-flex flex-column text-center">
            <span style="color: white">
              Compra exitosa!, regresa de cambio:
            </span>
            <div class="d-flex align-items-center justify-content-center">
              <b-badge
                pill
                :variant="'light-warning'"
                class="text-center"
                style="font-size: 30px"
              >
                ${{ giveBack.toFixed(2) }} MXN
              </b-badge>
            </div>
          </div>
        </b-col>
      </div>
      <div class="d-flex mt-2">
        <b-col cols="6">
          <b-button
            ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="success"
            @click="hideSweetAlertModal()"
          >
            Ok
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-warning"
            class="mr-2"
            type="button"
            @click="openOrderView"
          >
            Ver recibo
          </b-button>
        </b-col>
      </div>
    </b-modal>
  </div>
</template>

<script>
import numpad from '@/@core/components/NumPad.vue'
import Invoice from '@/@core/components/Invoice.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    numpad,
    Invoice,
  },
  data() {
    return {
      products_offers: {},
      orderIdForInvoice: null,
      giveBack: 0,
    }
  },
  computed: {
    ...mapGetters('pos', [
      'cart',
      'cartTotal',
      'restockMode',
      'currentRestockSupplier',
      'offlineProducts',
    ]),
    ...mapGetters('recommenders', ['recommenders']),
    ...mapGetters('promotions', ['promoCodePromotion']),
  },
  methods: {
    ...mapActions('orders', ['addOrder', 'dispatchOrder']),
    ...mapActions('printer', ['print']),
    ...mapActions('pos', ['emptyCart']),
    ...mapMutations('pos', [
      'SET_RESTOCK_MODE',
      'SET_SUPPLIER',
      'SET_CASHPAYMENT_MODE',
    ]),
    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }
      if (/android/i.test(userAgent)) {
        return 'Android'
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }
      return 'unknown'
    },
    openOrderView(event) {
      // Prevent the default anchor link behavior
      event.preventDefault()

      // Get the router instance
      const router = this.$router

      // Generate the link to the "order-view" route with the specified params
      const { href } = router.resolve({
        name: 'order-view',
        params: { id: this.orderIdForInvoice },
      })

      // Open the link in a new tab
      window.open(href, '_blank')
    },
    openModal() {
      this.SET_CASHPAYMENT_MODE(true)
      this.$bvModal.show('cash-numpad')
    },
    eliminarDiacriticos(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    // TODO: Manu Función para revisar los recommenders contra los productos del cart --> Revisar from, to, regresar todos los to que no están
    // Buscar la data dentro de lunr para tener que producto es

    openModal2() {
      this.$bvModal.show('sale-complete-modal')
    },

    hideSweetAlertModal() {
      this.$bvModal.hide('sale-complete-modal')
      this.$bvModal.hide('payments-modal')
      this.emptyCart()
    },
    viewInvoice() {
      this.$bvModal.show('invoice-modal')
    },
    completeSaleWithCash(cash) {
      this.SET_CASHPAYMENT_MODE(false)
      // eslint-disable-next-line camelcase
      const matches = this.recommenders.data.filter((item_lookUp) =>
        this.cart.some(
          (product_recommended) =>
            item_lookUp.from_product === product_recommended.id
        )
      )
      this.products_offers = matches.map(
        (recomendation) => this.offlineProducts[`${recomendation.to_product}`]
      )

      const order = {
        store_supplier_id:
          this.restockMode === true ? this.currentRestockSupplier.id : null,
        is_loan: false,
        store_id: this.$route.params.store_id,
        total: this.cartTotal,
        store_clerk: JSON.parse(localStorage.getItem('userData')),
        promo_code: this.promoCodePromotion
          ? this.promoCodePromotion.promo_code
          : '',
        order_store_products_attributes: this.cart.map((product) => ({
          store_product_id: product.id,
          units: product.units,
        })),
        order_payments_attributes: [
          {
            payment_type: 'cash',
            amount: this.cartTotal,
          },
        ],
      }
      this.giveBack = cash - this.cartTotal
      const setOrderType = this.restockMode === true ? 'buy' : 'sell'

      // console.log(navigator.connection.downlink)

      if (navigator.connection && navigator.connection.downlink > 0.5) {
        // eslint-disable-next-line
        console.log('regular connection')
        this.dispatchOrder({ order, orderType: setOrderType })
          .then((response) => {
            this.orderIdForInvoice = response.id
            this.SET_RESTOCK_MODE(false)
            this.SET_SUPPLIER(null)
            if (JSON.parse(localStorage.getItem('print')) === true) {
              // window.print()
              const orderToPrint = {
                order_type: {
                  payment: 'cash',
                  type: setOrderType,
                },
                store: this.eliminarDiacriticos(
                  this.cart[0].store_attributes.name
                ),
                products: this.cart.map((product) => ({
                  name: this.eliminarDiacriticos(
                    product.product_attributes.name
                  ),
                  variant: product.product_attributes.variant,
                  units: product.units,
                  unit_price: product.unit_price,
                })),
                subtotal: this.cartTotal - this.cartTotal * 0.16,
                iva: this.cartTotal * 0.16,
                total: this.cartTotal,
                date: Date.now(),
                giveBack: this.giveBack.toFixed(2),
                cashReceived: cash,
              }
              const device = this.getMobileOperatingSystem()
              console.log(device)
              if (device === 'Android') {
                // Serialize the orderToPrint object to a JSON string
                const orderJson = JSON.stringify(orderToPrint)

                // URL-encode the JSON string to include it in the intent URL
                const orderEncoded = encodeURIComponent(orderJson)

                // Define the second intent URL with the order included as a query parameter
                const intentUrl = `intent://print?directLink=true#Intent;scheme=extprint;package=com.compitienda.compitienda_printer;S.order=${orderEncoded};end`

                // Try to open the intent URL
                window.location.href = intentUrl
              } else {
                this.print({ ...orderToPrint })
                  .then(() => {
                    console.log('print success')
                  })
                  .catch(() => {
                    console.log('print error')
                  })
              }
            }
            // eslint-disable-next-line
            const audio = new Audio(require('@/assets/sounds/Success.wav'))
            audio.play()
            this.$bvModal.hide('cash-numpad')
            this.openModal2()
          })
          .catch((error) => {
            if (error.response.data.messages.base[0]) {
              this.SET_RESTOCK_MODE(false)
              this.SET_SUPPLIER(null)
              this.$bvModal.hide('cash-numpad')
              this.emptyCart()
              const audio = new Audio(require('@/assets/sounds/Denied.wav'))
              audio.play()
              this.$swal({
                text: error.response.data.messages.base[0],
                title: 'Algo salió mal!',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.addOrder({ order, orderType: setOrderType })
                .then(() => {
                  this.SET_RESTOCK_MODE(false)
                  this.SET_SUPPLIER(null)
                  if (JSON.parse(localStorage.getItem('print')) === true) {
                    // window.print()
                    const orderToPrint = {
                      order_type: {
                        payment: 'cash',
                        type: setOrderType,
                      },
                      store: this.eliminarDiacriticos(
                        this.cart[0].store_attributes.name
                      ),
                      products: this.cart.map((product) => ({
                        name: this.eliminarDiacriticos(
                          product.product_attributes.name
                        ),
                        variant: product.product_attributes.variant,
                        units: product.units,
                        unit_price: product.unit_price,
                      })),
                      subtotal: this.cartTotal - this.cartTotal * 0.16,
                      iva: this.cartTotal * 0.16,
                      total: this.cartTotal,
                      date: Date.now(),
                      giveBack: this.giveBack.toFixed(2),
                      cashReceived: cash,
                    }
                    this.print({ ...orderToPrint })
                  }
                  // eslint-disable-next-line
                  const audio = new Audio(
                    require('@/assets/sounds/Success.wav')
                  )
                  audio.play()
                  this.$swal({
                    text: 'Cobro exitoso!',
                    title: `Cambio: $ ${giveBack.toFixed(2)}`,
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: true,
                    showCancelButton: true,
                    cancelButtonText: 'Generar factura',
                  }).then((value) => {
                    if (value.isDismissed) {
                      this.$router.push({
                        name: 'generate-online-invoice',
                        query: { order_id: this.orderIdForInvoice },
                      })
                    }
                  })
                  this.emptyCart()
                  this.$bvModal.hide('cash-numpad')
                })
                .catch((error) => {
                  this.SET_RESTOCK_MODE(false)
                  this.SET_SUPPLIER(null)
                  if (error.response.data.messages[0]) {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                        text: error.response.data.messages[0],
                      },
                    })
                  }
                })
                .finally(() => {
                  this.$bvModal.hide('payments-modal')
                })
            }
          })
      } else {
        // eslint-disable-next-line
        console.log('slow connection')
        this.addOrder({ order, orderType: setOrderType })
          .then(() => {
            this.SET_RESTOCK_MODE(false)
            this.SET_SUPPLIER(null)
            if (JSON.parse(localStorage.getItem('print')) === true) {
              // window.print()
              const orderToPrint = {
                order_type: {
                  payment: 'cash',
                  type: setOrderType,
                },
                store: this.eliminarDiacriticos(
                  this.cart[0].store_attributes.name
                ),
                products: this.cart.map((product) => ({
                  name: this.eliminarDiacriticos(
                    product.product_attributes.name
                  ),
                  variant: product.product_attributes.variant,
                  units: product.units,
                  unit_price: product.unit_price,
                })),
                subtotal: this.cartTotal - this.cartTotal * 0.16,
                iva: this.cartTotal * 0.16,
                total: this.cartTotal,
                date: Date.now(),
                giveBack: this.giveBack.toFixed(2),
                cashReceived: cash,
              }
              this.print({ ...orderToPrint })
            }
            // eslint-disable-next-line
            const audio = new Audio(require('@/assets/sounds/Success.wav'))
            audio.play()
            this.$swal({
              text: 'Cobro exitoso!aaa',
              title: `Cambio: $ ${this.giveBack.toFixed(2)}`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.emptyCart()
            this.$bvModal.hide('cash-numpad')
            this.$bvModal.hide('payments-modal')
          })
          .catch((error) => {
            this.SET_RESTOCK_MODE(false)
            this.SET_SUPPLIER(null)
            if (error.response.data.messages[0]) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.messages[0],
                },
              })
            }
          })
          .finally(() => {
            this.$bvModal.hide('payments-modal')
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-button {
  font-size: 1.2rem !important;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  font-size: 1rem !important;

  span {
    font-weight: 900;
  }

  @media (min-width: 480px) {
    font-size: 1.2rem !important;

    img {
      width: 100px;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.5rem !important;
  }
}
</style>
