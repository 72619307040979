/* eslint-disable vue/valid-v-on */
<template>
  <div>
    <div
      class="service-card carsh-withdrawal"
      :class="cashWithdrawalMode === true ? 'active' : null"
      @click="openModal()"
    >
      <i class="fas fa-2x fa-money-bill mb-1" />
      Retiro de efectivo
    </div>
    <b-modal
      :id="`cashWithdrawalModal-${$route.params.store_id}`"
      title="Retirar efectivo"
      size="sm"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      :ok-disabled="cash_security_withdrawal < 0 || cash_security_withdrawal === null || cash_security_withdrawal === ''"
      @ok="handleCashWithdrawal({ storeId: $route.params.store_id, cash: cash_security_withdrawal })"
      @cancel="SET_CASHWITHDRAWAL_MODE(false)"
    >
      <b-form class="my-2">
        <h1 class="text-center display-3">
          💰
        </h1>
        <h3 class="text-center mb-1">
          ¿Cuánto efectivo estás retirando?
        </h3>
        <b-input-group>
          <b-input-group-prepend is-text>
            $
          </b-input-group-prepend>
          <b-form-input
            v-model="cash_security_withdrawal"
            type="number"
          />
        </b-input-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      cash_security_withdrawal: null,
    }
  },
  computed: {
    ...mapGetters('pos', ['cashWithdrawalMode']),
  },
  methods: {
    ...mapActions('stores', ['cashWithdrawal']),
    ...mapMutations('pos', ['SET_CASHWITHDRAWAL_MODE']),
    openModal() {
      this.SET_CASHWITHDRAWAL_MODE(true)
      this.$bvModal.show(`cashWithdrawalModal-${this.$route.params.store_id}`)
    },
    handleCashWithdrawal(data) {
      this.cashWithdrawal(data)
        .then(() => {
          this.SET_CASHWITHDRAWAL_MODE(false)
          this.$swal({
            title: '¡Retiro de efectivo exitoso!',
            text: 'Grácias.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.isActive = false
          error.response.data.messages.forEach(element => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: element,
              },
            })
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.active {
  background-color: $primary !important;
}

</style>
