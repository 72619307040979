<template>
  <div class="w-100 h-100">
    <b-button
      tabindex="4"
      variant="shadow"
      size="md"
      class="w-100 h-100 payment-button"
      @click="confirmModal()"
    >
      <b-img :src="require('@/assets/images/logo/bank_card.png')" width="80" class="img"/>
      <span class="py-2">Tarjeta bancaria</span>
    </b-button>
    <b-modal
      id="invoice-modal-bankcard"
      title="Venta exitosa"
      size="lg"
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      ok-only
      :hide-header="true"
      @ok="emptyCart()"
      >
      <invoice :order-id="orderIdForInvoice"/>
    </b-modal>
    <b-modal 
      id="sale-complete-modal-bankcard" 
      title="Venta exitosa" 
      class="modal-content" 
      no-stacking
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      :hide-header="true" >
        <div class="modal-body d-flex align-items-center justify-content-center">
          <b-col>
            <div class="d-flex align-items-center justify-content-center">
              <b-badge pill :variant="'light-warning'" class="text-center" style="font-size: 30px;">
                Venta registrada!
              </b-badge>
            </div>
          </b-col>
        </div>
        <div class="d-flex mt-2">
          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="success"
              @click="hideSweetAlertModalBankcard()"
            >
              Ok
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              class="mr-2"
              type="button"
              @click="openOrderView"
            >
              Ver recibo
            </b-button>
          </b-col>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Invoice from '@/@core/components/Invoice.vue'

export default {
  components: {
    Invoice,
  },
  data() {
    return {
      orderIdForInvoice: null,
      giveBack: 0,
    }
  },
  computed: {
    ...mapGetters('pos', ['cart', 'cartTotal', 'restockMode', 'currentRestockSupplier']),
    ...mapGetters('promotions', ['promoCodePromotion']),
  },
  methods: {
    ...mapActions('orders', ['addOrder', 'dispatchOrder']),
    ...mapActions('printer', ['print']),
    ...mapActions('pos', [
      'emptyCart',
    ]),
    ...mapMutations('pos', [
      'SET_RESTOCK_MODE',
      'SET_SUPPLIER',
    ]),

    openOrderView(event) {
      // Prevent the default anchor link behavior
      event.preventDefault();

      // Get the router instance
      const router = this.$router;

      // Generate the link to the "order-view" route with the specified params
      const { href } = router.resolve({
        name: 'order-view',
        params: { id: this.orderIdForInvoice },
      });

      // Open the link in a new tab
      window.open(href, '_blank');
    },
    
    confirmModal() {
      this.$swal({
        title: 'Confirma el pago tarjeta exitoso',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.completeSaleWithCreditCard()
        }
      })
    },
    eliminarDiacriticos(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    openModalBankcard() {
      this.$bvModal.show('sale-complete-modal-bankcard')
    },

    hideSweetAlertModalBankcard() {
      this.$bvModal.hide('sale-complete-modal-bankcard')
    },
    viewInvoiceBankcard() {
      this.$bvModal.show('invoice-modal-bankcard')
    },
    completeSaleWithCreditCard() {
      const order = {
        store_supplier_id: this.restockMode === true ? this.currentRestockSupplier.id : null,
        is_loan: false,
        store_id: this.$route.params.store_id,
        total: this.cartTotal,
        store_clerk: JSON.parse(localStorage.getItem('userData')),
        promo_code: this.promoCodePromotion ? this.promoCodePromotion.promo_code : '',
        order_store_products_attributes: this.cart.map(product => ({
          store_product_id: product.id,
          units: product.units,
        })),
        order_payments_attributes: [
          {
            payment_type: 'bankcard',
            amount: this.cartTotal
          },
        ],
      }

      const setOrderType = this.restockMode === true ? 'buy' : 'sell'
      const cash = this.cartTotal
      this.giveBack = cash- this.cartTotal
      
      if (navigator.connection && navigator.connection.downlink > 0.5) {
        // eslint-disable-next-line
        console.log('regular connection')
        this.dispatchOrder({ order, orderType: setOrderType })
          .then(response => {
            this.orderIdForInvoice = response.id
            this.SET_RESTOCK_MODE(false)
            this.SET_SUPPLIER(null)
            if (JSON.parse(localStorage.getItem('print')) === true) {
              // window.print()
              const orderToPrint = {
                order_type: {
                  payment: 'bankcard',
                  type: setOrderType,
                },
                store: this.eliminarDiacriticos(this.cart[0].store_attributes.name),
                products: this.cart.map(product => ({
                  name: this.eliminarDiacriticos(product.product_attributes.name),
                  variant: product.product_attributes.variant,
                  units: product.units,
                  unit_price: product.unit_price,
                })),
                subtotal: this.cartTotal - this.cartTotal * 0.16,
                iva: this.cartTotal * 0.16,
                total: this.cartTotal,
                date: Date.now(),
                giveBack: this.giveBack.toFixed(2),
                cashReceived: cash,
              }
              this.print({ ...orderToPrint })
                .then(() => {
                  console.log('print success')
                })
                .catch(() => {
                  console.log('print error')
                })
            }
            // eslint-disable-next-line
            const audio = new Audio(require('@/assets/sounds/Success.wav'))
            audio.play()
            this.$bvModal.hide('cash-numpad')
            this.emptyCart()
            this.openModalBankcard()
          })
          .catch((error) => {
            if (error.response.data.messages.base[0]) {
              this.SET_RESTOCK_MODE(false)
              this.SET_SUPPLIER(null)
              this.$bvModal.hide('cash-numpad')
              this.emptyCart()
              const audio = new Audio(require('@/assets/sounds/Denied.wav'))
              audio.play()
              this.$swal({
                text: error.response.data.messages.base[0],
                title: 'Algo salió mal!',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.addOrder({ order, orderType: setOrderType })
                .then(() => {
                  this.SET_RESTOCK_MODE(false)
                  this.SET_SUPPLIER(null)
                  if (JSON.parse(localStorage.getItem('print')) === true) {
                    // window.print()
                    const orderToPrint = {
                      order_type: {
                        payment: 'bankcard',
                        type: setOrderType,
                      },
                      store: this.eliminarDiacriticos(this.cart[0].store_attributes.name),
                      products: this.cart.map(product => ({
                        name: this.eliminarDiacriticos(product.product_attributes.name),
                        variant: product.product_attributes.variant,
                        units: product.units,
                        unit_price: product.unit_price,
                      })),
                      subtotal: this.cartTotal - this.cartTotal * 0.16,
                      iva: this.cartTotal * 0.16,
                      total: this.cartTotal,
                      date: Date.now(),
                      giveBack: this.giveBack.toFixed(2),
                      cashReceived: cash,
                    }
                    this.print({ ...orderToPrint })
                  }
                  // eslint-disable-next-line
                  const audio = new Audio(require('@/assets/sounds/Success.wav'))
                  audio.play()
                  this.emptyCart()
                  this.$bvModal.hide('cash-numpad')
                }).catch(error => {
                  this.SET_RESTOCK_MODE(false)
                  this.SET_SUPPLIER(null)
                  if (error.response.data.messages[0]) {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                        text: error.response.data.messages[0],
                      },
                    })
                  }
                })
            }
          })
          .finally(() => {
            this.$bvModal.hide('payments-modal')
          })
      } else {
        // eslint-disable-next-line
        console.log('slow connection')
        this.addOrder({ order, orderType: setOrderType })
          .then(() => {
            this.SET_RESTOCK_MODE(false)
            this.SET_SUPPLIER(null)
            if (JSON.parse(localStorage.getItem('print')) === true) {
              // window.print()
              const orderToPrint = {
                order_type: {
                  payment: 'bankcard',
                  type: setOrderType,
                },
                store: this.eliminarDiacriticos(this.cart[0].store_attributes.name),
                products: this.cart.map(product => ({
                  name: this.eliminarDiacriticos(product.product_attributes.name),
                  variant: product.product_attributes.variant,
                  units: product.units,
                  unit_price: product.unit_price,
                })),
                subtotal: this.cartTotal - this.cartTotal * 0.16,
                iva: this.cartTotal * 0.16,
                total: this.cartTotal,
                date: Date.now(),
                giveBack: this.giveBack.toFixed(2),
                cashReceived: cash,
              }
              this.print({ ...orderToPrint })
            }
            // eslint-disable-next-line
            const audio = new Audio(require('@/assets/sounds/Success.wav'))
            audio.play()
            this.emptyCart()
            this.$bvModal.hide('cash-numpad')
          }).catch(error => {
            this.SET_RESTOCK_MODE(false)
            this.SET_SUPPLIER(null)
            if (error.response.data.messages[0]) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.messages[0],
                },
              })
            }
          })
          .finally(() => {
            this.$bvModal.hide('payments-modal')
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-button {
  font-size: 1.2rem !important;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  font-size: 1rem !important;

  span {
    font-weight: 900;
  }

  @media (min-width: 480px) { 
    font-size: 1.2rem !important;

    img {
      width: 100px;
    }
  
  }
  
  @media (min-width: 768px) {
    font-size: 1.5rem !important;

  }
}

</style>
