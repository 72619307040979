<template>
  <div>
    <div
      class="service-card return-card"
      :class="productInventoryMode === true ? 'active' : null"
      @click="productInventoryMode === true ? SET_PRODUCT_INVENTORY_MODE(false) : SET_PRODUCT_INVENTORY_MODE(true)"
    >
      <i class="fas fa-2x fa-truck-loading mb-1" />
      Inventariar
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('pos', ['productInventoryMode']),
  },
  methods: {
    ...mapMutations('pos', ['SET_PRODUCT_INVENTORY_MODE']),
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.active {
  background-color: $primary !important;
}
</style>
