<template>
  <div>
    <div class="info mb-0 p-3 d-flex flex-column h2 shadow">
      <b-row v-if="cart.length">
        <b-col v-if="productInventoryMode === false" class="mb-1" cols="12">
          <h1 v-if="restockMode === true" class="text-center">
            Comprar a proveedor {{ currentRestockSupplier.name }}
          </h1>
          <div
            class="
              d-flex
              justify-content-between
              text-warning
              mt-50
              h4
              responsive-text
            "
          >
            Descuento:
            <span
              >$ {{ (cartTotalNoPromotionApplied - cartTotal) | money }}</span
            >
          </div>
          <div
            class="
              d-flex
              justify-content-between
              text-warning
              mt-50
              h4
              responsive-text
            "
          >
            Subtotal:
            <span>$ {{ (cartTotal - cartTotal * 0.16) | money }}</span>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              text-warning
              mt-50
              h4
              responsive-text
            "
          >
            IVA: <span>$ {{ (cartTotal * 0.16) | money }}</span>
          </div>
          <div
            class="
              d-flex
              justify-content-between
              text-success
              mt-50
              responsive-text
            "
          >
            Total: <span>$ {{ cartTotal | money }}</span>
          </div>
        </b-col>
        <b-col v-if="productInventoryMode === true" class="py-1">
          <update-inventory-button />
        </b-col>
        <b-col v-else>
          <validate-promo-code />
          <button
            class="
              btn btn-primary btn-block
              text-uppercase
              py-2
              payment-button
              mt-1
            "
            @click="$bvModal.show('payments-modal')"
          >
            Pagar
          </button>
        </b-col>
      </b-row>
      <div v-else class="d-flex justify-content-center">
        <b-img
          :src="require('@/assets/images/logo/walleat_logo_white.svg')"
          fluid
          alt="Responsive image"
          class="logo"
        />
      </div>
    </div>

    <codi-payment-modal
      :loading.sync="loading"
      :completeOnlineOrder="completeOrder"
      :order.sync="order"
      :handleCancelCodiModal="handleCancelCodiModal"
    />

    <codi-info-modal />

    <pos-new-customer-modal :order.sync="order" />

    <pos-preview-modal />

    <bank-deposit-modal
      :loading.sync="loading"
      :completeOrder="completeOrder"
      :order.sync="order"
    />

    <b-modal
      id="payments-modal"
      title="Selecciona el método de pago"
      class="modal-content"
      ok-variant="outline-warning"
      ok-title="Regresar"
      ok-only
      no-close-on-backdrop
    >
      <div class="payments-grid">
        <div class="payment shadow border">
          <walleat-payment-button />
        </div>

        <div class="payment shadow border">
          <codi-payment-button />
        </div>

        <div class="payment shadow border">
          <cash-payment-button />
        </div>

        <div class="payment shadow border">
          <bankcard-payment-button />
        </div>

        <div
          v-if="
            currentStoreAcceptedPaymentTypes.includes('credit') &&
            restockMode === true
          "
          class="payment shadow border"
        >
          <credit-payment-button />
        </div>

        <div
          v-if="currentStoreAcceptedPaymentTypes.includes('bank_deposit')"
          class="payment shadow border"
        >
          <bankdeposit-payment-button />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import CashPaymentButton from "@/views/pos/CashPaymentButton.vue";
import BankcardPaymentButton from "@/views/pos/BankcardPaymentButton.vue";
import CreditPaymentButton from "@/views/pos/CreditPaymentButton.vue";
import CodiInfoModal from "@/@core/components/WallEateCommerce/CodiInfoModal.vue";
import WalleatPaymentButton from "@/views/pos/WalleatPaymentButton.vue";
import UpdateInventoryButton from "@/views/pos/UpdateInventoryButton.vue";
import ValidatePromoCode from "@/views/pos/ValidatePromoCode.vue";
import CodiPaymentButton from "@/views/pos/CodiPaymentButton.vue";
import BankdepositPaymentButton from "@/views/pos/BankdepositPaymentButton.vue";
import CodiPaymentModal from "@/@core/components/WallEateCommerce/CodiPaymentModal.vue";
import PosNewCustomerModal from "./PosNewCustomerModal.vue";
import PosPreviewModal from "./PosPreviewModal.vue";
import BankDepositModal from "./BankDepositModal.vue";

export default {
  components: {
    CashPaymentButton,
    BankcardPaymentButton,
    CreditPaymentButton,
    WalleatPaymentButton,
    UpdateInventoryButton,
    ValidatePromoCode,
    CodiPaymentButton,
    BankdepositPaymentButton,
    CodiInfoModal,
    CodiPaymentModal,
    PosNewCustomerModal,
    PosPreviewModal,
    BankDepositModal,
  },
  data() {
    return {
      loading: false,
      order: {
        cel_number: "",
      },
      acceptedPayments: [],
    };
  },
  computed: {
    ...mapGetters("pos", [
      "cartTotal",
      "cartTotalNoPromotionApplied",
      "cart",
      "restockMode",
      "productInventoryMode",
      "currentRestockSupplier",
    ]),
    ...mapGetters("stripe", ["storeAccount"]),
    ...mapGetters("stores", ["currentStoreAcceptedPaymentTypes"]),
  },
  beforeMount() {
    this.emptyCart();
    this.fetchStoreAccount({ id: this.$route.params.store_id });
  },
  methods: {
    ...mapActions("pos", ["emptyCart"]),
    ...mapActions("stores", ["fetchStore"]),
    ...mapActions("stripe", ["checkoutBankTransfer", "fetchStoreAccount"]),
    ...mapActions("orders", ["dispatchPendingOrder"]),
    ...mapMutations("pos", ["SET_BANK_DEPOSIT_MODE", "SET_CODI_PAYMENT_MODE"]),

    handleCancelCodiModal() {
      this.SET_CODI_PAYMENT_MODE(false);
      this.$bvModal.hide("complete-codi-payment");
    },

    setOrder(paymentType) {
      let order = {
        store_id: this.$route.params.store_id,
        cel_number: `${52}${this.order.cel_number}`,
        from_store_id: this.$route.params.from_store_id,
        order_store_products_attributes: this.cart.map((product) => ({
          store_product_id: product.id,
          units: product.units,
        })),
        order_payments_attributes: [
          {
            payment_type: paymentType,
            amount: this.cartTotal,
          },
        ],
      };

      return order;
    },
    handleBankDepositPayment(res) {
      this.$swal({
        title: "Generando pago...",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      this.checkoutBankTransfer({
        checkout_session: {
          store_account_id: this.storeAccount.id,
          online_pending_order_id: res.id,
          price: JSON.parse(res.pending_order).total,
          success_url: window.location.href,
          cancel_url: window.location.href,
          customer_phone_number: `52${this.order.cel_number}`,
        },
      })
        .then((res) => {
          this.$swal.close();
          this.$bvModal.hide("bank-deposit-modal");
          window.open(res.session_url, "_blank");
        })
        .catch((e) => {
          //const messaageError = e.response.data.messages
          this.$bvModal.hide("bank-deposit-modal");
          this.SET_BANK_DEPOSIT_MODE(false);
          this.$swal({
            title: "ERROR!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    handlePaymentWithCodi(res) {
      this.$swal({
        title: "Cobro solicitado!",
        text: "En unos momentos más llegará una notificación en la aplicación bancaria del cliente comprador. Pídele al cliente que autorice el cobro en su celular durante los primeros 15 minutos para poder procesar la compra, de otra forma se cancelará automáticamente la transacción",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.$bvModal.hide("complete-codi-payment");
    },
    completeOrder(paymentType) {
      this.loading = true;

      let order = this.setOrder(paymentType);

      const paymentHandlers = {
        codi: this.handlePaymentWithCodi,
        bank_deposit: this.handleBankDepositPayment,
      };

      const paymentHandler = paymentHandlers[paymentType];

      if (paymentType === "bank_deposit" && this.cartTotal < 10) {
        this.$swal({
          title: "ERROR!",
          text: "El monto mínimo para transferencia bancaria es de $10.00",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.loading = false;
        this.SET_BANK_DEPOSIT_MODE(false);
        return;
      }

      if (paymentType === 'codi' && this.cartTotal > 8000) {
        this.$swal({
          title: "ERROR!",
          text: "El monto máximo para este método de pago es de  $8,000.00",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.loading = false;
        this.SET_BANK_DEPOSIT_MODE(false);
        return;
      }

      this.dispatchPendingOrder({ order })
        .then((res) => {
          const audio = new Audio(require("@/assets/sounds/Success.wav"));
          audio.play();
          this.$bvModal.hide("cash-numpad");
          paymentHandler.call(this, res);
          this.emptyCart();
          this.order = {
            cel_number: "",
          };
        })
        .catch((e) => {
          const audio = new Audio(require("@/assets/sounds/Denied.wav"));
          audio.play();
          const errorMessage = e.response.data.messages;
          this.$swal({
            title: errorMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .finally(() => {
          this.loading = false;
          this.SET_BANK_DEPOSIT_MODE(false);
          this.SET_CODI_PAYMENT_MODE(false);
          this.$bvModal.hide("payments-modal");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-layout {
  .info {
    background-color: #0e1322 !important;
  }
}

.info {
  @extend .shadow-lg !optional;
  z-index: 2;
  padding: 20px !important;
  border-radius: 30px 0 0 0;
  margin-top: -25px;
}

.payments-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  .payment {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: transform 0.2s ease;
    min-height: 130px;

    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: 360px) {
    grid-template-columns: 1fr 1fr;
  }
}

.payment-button {
  font-weight: 700;
  font-size: 1.1rem;
}

.responsive-text {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .responsive-text {
    font-size: 1.2rem;
  }

  .payment-button {
    font-weight: 700;
    font-size: 1.3rem;
  }
}

.logo {
  filter: invert(20%);
  transition: filter 0.3s ease;
}

@media (prefers-color-scheme: dark) {
  .logo {
    filter: none;
  }
}
</style>
