<template>
  <b-modal
    id="loadingOffline"
    hide-header
    hide-footer
    centered
    class="p-4"
    no-close-on-esc
    no-close-on-backdrop
  >
    <div>
      <div
        v-if="loadingOffline"
        class="p-2 d-flex justify-content-center align-items-center flex-column"
      >
        <i
          class="fas fa-spinner fa-spin fa-5x mb-4"
        />
        <h3 class="mb-0">
          Un momento
        </h3>
        <p>
          Estamos actualizando la base de datos de productos
        </p>
        <div class="w-100 mb-2">
          <b-progress
            :value="currentFetchPage"
            :max="lastFetchPage"
            show-progress
            animated
            height="2rem"
          />
        </div>
      </div>
      <div
        class="p-2 d-flex justify-content-center align-items-center flex-column"
        v-else
      >
        <i
          class="fas fa-check fa-5x mb-4"
        />
        <h3 class="mb-0">
          Todo bien
        </h3>
        <p>
          Se actualizaron {{ storeProductsCount }} productos
        </p>
        <b-button
          variant="success"
          @click="$bvModal.hide('loadingOffline')"
        >
          Continuar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('storeProducts', ['currentFetchPage', 'lastFetchPage', 'loadingOffline', 'storeProductsCount']),
  },
}
</script>
