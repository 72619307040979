var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"bank-deposit-modal","title":"Transferencia bancaria","size":"lg","no-stacking":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header":true,"hide-footer":""}},[_c('validation-observer',{ref:"celRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleCompleteOrder.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Ingresa numero de celular registrado","label-for":"cel-input","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"required|digits:10","vid":"cel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"cel-input","placeholder":"Celular a 10 dígitos","size":"lg","type":"number","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.order.cel_number),callback:function ($$v) {_vm.$set(_vm.order, "cel_number", $$v)},expression:"order.cel_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Confirma el número de celular","label-for":"cel-input-confirm","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"confirmed:cel|digits:10|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"cel-input-confirm","placeholder":"Celular a 10 dígitos","size":"lg","type":"number","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.confirmCel),callback:function ($$v) {_vm.confirmCel=$$v},expression:"confirmCel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"disabled":_vm.loading,"variant":"outline-danger","size":"lg"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Atras ")]),_c('b-button',{staticClass:"bold",attrs:{"disabled":_vm.loading || invalid,"variant":invalid ? 'outline-danger' : 'success',"type":"submit","size":"lg"}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Aceptar ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }