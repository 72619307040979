var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"pos-new-customer-modal","title":"Transferencia bancaria","size":"lg","no-stacking":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"outline-danger"},on:{"click":function($event){return _vm.handleBack()}}},[_vm._v(" Atras ")])]},proxy:true}])},[_c('validation-observer',{ref:"addPayeeRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Nombre","label-for":"c-name","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Nombre completo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-name","size":"lg","type":"text","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Email","label-for":"c-email","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-email","placeholder":"Correo","size":"lg","type":"email","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Telefono","label-for":"c-phone","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-phone","placeholder":"Celular a 10 dígitos","size":"lg","type":"number","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Direccion (opcional)","label-for":"c-address","label-size":"lg"}},[_c('b-button',{attrs:{"id":"c-address","variant":"outline-primary"},on:{"click":function($event){_vm.addAddress = !_vm.addAddress}}},[(_vm.addAddress)?_c('span',[_vm._v("Ocultar")]):_c('span',[_vm._v("Agregar dirección")])])],1),(_vm.addAddress)?_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Direccion","label-for":"c-address","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Calle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-address","placeholder":"Calle","size":"lg","type":"text","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.addressData.street),callback:function ($$v) {_vm.$set(_vm.addressData, "street", $$v)},expression:"addressData.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.addAddress)?_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Numero exterior","label-for":"c-ext-number","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Numero exterior","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-ext-number","placeholder":"Numero exterior","size":"lg","type":"text","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.addressData.ext_number),callback:function ($$v) {_vm.$set(_vm.addressData, "ext_number", $$v)},expression:"addressData.ext_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.addAddress)?_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Numero interior (opcional)","label-for":"c-int-number","label-size":"lg"}},[_c('b-form-input',{attrs:{"id":"c-int-number","placeholder":"Numero interior","size":"lg","type":"text"},model:{value:(_vm.addressData.int_number),callback:function ($$v) {_vm.$set(_vm.addressData, "int_number", $$v)},expression:"addressData.int_number"}})],1):_vm._e(),(_vm.addAddress)?_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Colonia","label-for":"c-suburb","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Colonia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-suburb","placeholder":"Colonia","size":"lg","type":"text","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.addressData.suburb),callback:function ($$v) {_vm.$set(_vm.addressData, "suburb", $$v)},expression:"addressData.suburb"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.addAddress)?_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Codigo postal","label-for":"c-postal-code","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Codigo postal","rules":"required|digits:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-postal-code","placeholder":"Codigo postal","size":"lg","type":"number","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.addressData.postal_code),callback:function ($$v) {_vm.$set(_vm.addressData, "postal_code", $$v)},expression:"addressData.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.addAddress)?_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Ciudad","label-for":"c-city","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-city","placeholder":"Ciudad","size":"lg","type":"text","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.addressData.city),callback:function ($$v) {_vm.$set(_vm.addressData, "city", $$v)},expression:"addressData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.addAddress)?_c('b-form-group',{attrs:{"id":"fieldset-1","label":"Estado","label-for":"c-state","label-size":"lg"}},[_c('validation-provider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"c-state","placeholder":"Estado","size":"lg","type":"text","state":errors.length > 0 ? false: (valid ? true : null)},model:{value:(_vm.addressData.state),callback:function ($$v) {_vm.$set(_vm.addressData, "state", $$v)},expression:"addressData.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"submit-buttons"},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.loading,"size":"lg"}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Guardar ")],1),_c('b-button',{attrs:{"type":"reset","variant":"danger","size":"lg"}},[_vm._v(" Limpiar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }