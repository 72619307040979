<template>
  <b-modal
    id="pos-new-customer-modal"
    title="Transferencia bancaria"
    size="lg"
    no-stacking
    no-close-on-esc
    no-close-on-backdrop
    :hide-header="true"
  >
    <validation-observer
      ref="addPayeeRules"
      v-slot="{ invalid }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit()"
      >
        <b-form-group
          id="fieldset-1"
          label="Nombre"
          label-for="c-name"
          label-size="lg"
        >  
          <validation-provider
            #default="{ errors, valid }"
            name="Nombre completo"
            rules="required"
          >
            <b-form-input 
              id="c-name"
              v-model="customer.name"
              size="lg"
              type="text"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          id="fieldset-1"
          label="Email"
          label-for="c-email"
          label-size="lg"
        >  
          <validation-provider
            #default="{ errors, valid }"
            name="Celular"
            rules="required|email"
          >
            <b-form-input 
              id="c-email"
              v-model="customer.email"
              placeholder="Correo"
              size="lg"
              type="email"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          id="fieldset-1"
          label="Telefono"
          label-for="c-phone"
          label-size="lg"
        >  
          <validation-provider
            #default="{ errors, valid }"
            name="Celular"
            rules="required|digits:10"
          >
            <b-form-input 
              id="c-phone"
              v-model="customer.phone"
              placeholder="Celular a 10 dígitos"
              size="lg"
              type="number"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          id="fieldset-1"
          label="Direccion (opcional)"
          label-for="c-address"
          label-size="lg"
        > 
          <b-button
            id="c-address"
            variant="outline-primary"
            @click="addAddress = !addAddress"
          >
            <span v-if="addAddress">Ocultar</span>
            <span v-else>Agregar dirección</span>
          </b-button> 
        </b-form-group>

        <b-form-group
          v-if="addAddress"
          id="fieldset-1"
          label="Direccion"
          label-for="c-address"
          label-size="lg"
        > 
          <validation-provider
            #default="{ errors, valid }"
            name="Calle"
            rules="required"
          >
            <b-form-input 
              id="c-address"
              v-model="addressData.street"
              placeholder="Calle"
              size="lg"
              type="text"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          v-if="addAddress"
          id="fieldset-1"
          label="Numero exterior"
          label-for="c-ext-number"
          label-size="lg"
        >
          <validation-provider
            #default="{ errors, valid }"
            name="Numero exterior"
            rules="required"
          >
            <b-form-input 
              id="c-ext-number"
              v-model="addressData.ext_number"
              placeholder="Numero exterior"
              size="lg"
              type="text"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          v-if="addAddress"
          id="fieldset-1"
          label="Numero interior (opcional)"
          label-for="c-int-number"
          label-size="lg"
        >
          <b-form-input 
            id="c-int-number"
            v-model="addressData.int_number"
            placeholder="Numero interior"
            size="lg"
            type="text"
          />  
        </b-form-group>

        <b-form-group
          v-if="addAddress"
          id="fieldset-1"
          label="Colonia"
          label-for="c-suburb"
          label-size="lg"
        >
          <validation-provider
            #default="{ errors, valid }"
            name="Colonia"
            rules="required"
          >
            <b-form-input 
              id="c-suburb"
              v-model="addressData.suburb"
              placeholder="Colonia"
              size="lg"
              type="text"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          v-if="addAddress"
          id="fieldset-1"
          label="Codigo postal"
          label-for="c-postal-code"
          label-size="lg" 
        >
          <validation-provider
            #default="{ errors, valid }"
            name="Codigo postal"
            rules="required|digits:5"
          >
            <b-form-input 
              id="c-postal-code"
              v-model="addressData.postal_code"
              placeholder="Codigo postal"
              size="lg"
              type="number"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          v-if="addAddress"
          id="fieldset-1"
          label="Ciudad"
          label-for="c-city"
          label-size="lg"
        >
          <validation-provider
            #default="{ errors, valid }"
            name="Ciudad"
            rules="required"
          >
            <b-form-input 
              id="c-city"
              v-model="addressData.city"
              placeholder="Ciudad"
              size="lg"
              type="text"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          v-if="addAddress"
          id="fieldset-1"
          label="Estado"
          label-for="c-state"
          label-size="lg"
        >
          <validation-provider
            #default="{ errors, valid }"
            name="Estado"
            rules="required"
          >
            <b-form-input 
              id="c-state"
              v-model="addressData.state"
              placeholder="Estado"
              size="lg"
              type="text"
              :state="errors.length > 0 ? false: (valid ? true : null)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        
        <div class="submit-buttons">
          <b-button 
            type="submit" 
            variant="primary"
            :disabled="invalid || loading"
            size="lg"
          >
            <b-spinner
              v-if="loading"
              small
            />
            Guardar
          </b-button>
          <b-button 
            type="reset" 
            variant="danger" 
            size="lg" 
          >
            Limpiar
          </b-button>
        </div>
      </b-form>
    </validation-observer>
    
    <template #modal-footer>
      <b-button
        :disabled="loading"
        variant="outline-danger"
        @click="handleBack()"
      >
        Atras
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data(){
    return {
      customer: {
        name: '',
        email: '',
        phone: '',
      },
      addressData: {
        street: '',
        ext_number: '',
        int_number: '',
        suburb: '',
        postal_code: '',
        city: '',
        state: '',
        country: 'México',
      },
      addAddress: false,
      loading: false,
    }
  },
  props: {
    order: {
      type: Object,
      validator: (p) => {
        return ['object'].indexOf(typeof p) !== -1 || p === null;
      },
    }
  },
  computed: {
    ...mapGetters('pos', ['codiPaymentMode', 'bankDepositMode']),
  },
  methods: {
    ...mapActions('users', ['registerCustomer']),
    handleBack(){
      this.$bvModal.hide('pos-new-customer-modal')
      this.$bvModal.show('pos-preview-modal')
    },
    handleSubmit(){
      this.loading = true

      const user = {
        name: this.customer.name,
        email: this.customer.email,
        phone_number: `${52}${this.customer.phone}`,
        customer_attributes: {
          cel_number: `${52}${this.customer.phone}`,
          address_attributes: this.addressData,
        },
      }

      this.registerCustomer(user)
      .then((res) => {
        this.loading = false
        this.$bvModal.hide('pos-new-customer-modal')
        this.$swal({
          title: 'Usuario registrado exitosamente!',
          text: 'Revisa tus mensajes de texto para terminar tu registro.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        this.order.cel_number = res.phone_number.substring(2)

        if(this.codiPaymentMode){
          this.$bvModal.show('complete-codi-payment')
        } else if(this.bankDepositMode){
          this.$bvModal.show('bank-deposit-modal')
        }
      })
      .catch((e) => {
        if (e.response.data.messages) {
          this.$swal({
            title: 'Error!',
            text: e.response.data.messages[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
}

</script>


<style lang="scss" scoped>

.submit-buttons{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}
</style>
