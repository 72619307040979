<template>
  <b-card
    no-body
    class="bg-transparent mb-1"
  >
    <div class="d-flex justify-content-between align-items-center p-1">
      <div class="d-flex align-items-center">
        <clock class="ml-50" />
      </div>
      <div class="font-weight-bolder">
        {{ store.name }}
      </div>
      <div class="d-flex align-items-center">
        <i
          v-if="loadingOffline"
          v-b-modal.loadingOffline
          class="fas fa-spinner fa-spin mr-2 fa-2x text-danger"
        />
        <i
          v-if="connectionIndicator"
          tabindex="-1"
          class="fas fa-weight mr-1 text-success"
        />
        <i
          v-b-toggle.posSettingsSidebar
          tabindex="-1"
          class="fas fa-cog fa-2x mr-1"
        />        
        <b-avatar
          size="30"
          :src="`${userData.logo}`"
          variant="light-primary"
        >
          <feather-icon
            v-if="!userData.name"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Clock from '@/components/Clock.vue'

export default {
  components: {
    Clock,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      store: {},
    }
  },
  computed: {
    ...mapGetters('pos', ['offlineMode', 'offlineProducts']),
    ...mapGetters('weight', ['connectionIndicator']),
    ...mapGetters('storeProducts', ['loadingOffline']),
  },
  mounted() {
    this.fetchStore(this.$route.params.store_id)
      .then(response => {
        this.store = response
      })
  },
  methods: {
    ...mapActions('stores', ['fetchStore']),
  },
}
</script>

<style lang="scss" scoped>

</style>
