<template>
  <div class="status-bar shadow">
    <span v-if="lunrCtx && lunrCtx.fieldVectors" class="text-muted">
      {{ Object.keys(lunrCtx.fieldVectors).length / 2 }} Productos
    </span>
    <span v-else class="text-danger cursor-pointer" @click="handleFetchOffline">
      <i class="fas fa-circle" />
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("pos", ["lunrCtx"]),
  },
  methods: {
    ...mapActions("storeProducts", ["fetchOfflineStoreProducts"]),
    handleFetchOffline() {
      this.$bvModal.show("loadingOffline");
      this.fetchOfflineStoreProducts(this.$route.params.store_id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";
.dark-layout {
  .status-bar {
    background-color: #12182c !important;
  }
}

.status-bar {
  position: relative;
  width: 100%;
  background-color: $white;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .status-bar {
    display: none;
  }
}
</style>
