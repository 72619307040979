<template>
  <div>
    <div
      class="service-card return-card"
      :class="isActive === true ? 'active' : null"
      @click="refundConfirmationModal()"
    >
      <i class="fas fa-2x fa-undo mb-1" />
      Devolución
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    ...mapGetters('pos', [
      'cart',
      'cartTotal',
    ]),
  },
  methods: {
    ...mapActions('printer', ['print']),
    ...mapActions('orders', ['addOrder', 'dispatchOrder']),
    ...mapActions('pos', [
      'emptyCart',
    ]),
    eliminarDiacriticos(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    refundConfirmationModal() {
      if (!this.cart.length) {
        this.$swal({
          title: 'Error',
          text: 'Selecciona primero los productos para poder hacer una devolución.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.isActive = true
        this.paymentMethod = 'refund'
        this.$bvModal.msgBoxConfirm('Por favor confirma que deseas realizar el reembolso.', {
          title: 'Reembolso',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SÍ',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
          .then(value => {
            if (value === true) {
              const orderReady = {
                store_id: this.$route.params.store_id,
                is_loan: false,
                store_clerk: JSON.parse(localStorage.getItem('userData')),
                created_at: new Date(),
                total: this.cartTotal,
                order_store_products_attributes: this.cart.map(product => ({
                  store_product_id: product.id,
                  units: product.units,
                })),
                order_payments_attributes: [
                  {
                    payment_type: 'cash',
                    amount: this.cartTotal
                  },
                ],
              }
              if (navigator.connection && navigator.connection.downlink > 0.5) {
                // eslint-disable-next-line
                console.log('regular connection')
                this.dispatchOrder({ order: orderReady, orderType: 'sell', isRefund: 'true' })
                  .then(() => {
                    this.isActive = false
                    if (JSON.parse(localStorage.getItem('print')) === true) {
                      const order = {
                        order_type: {
                          payment: 'cash',
                          type: 'sell',
                        },
                        is_refund: true,
                        store: this.eliminarDiacriticos(this.cart[0].store_attributes.name),
                        products: this.cart.map(product => ({
                          name: this.eliminarDiacriticos(product.product_attributes.name),
                          variant: product.product_attributes.variant,
                          units: product.units,
                          unit_price: product.unit_price,
                        })),
                        subtotal: this.cartTotal - this.cartTotal * 0.16,
                        iva: this.cartTotal * 0.16,
                        total: this.cartTotal,
                        date: Date.now(),

                      }
                      this.print({ ...order })
                    }
                    // eslint-disable-next-line
                    const audio = new Audio(require('@/assets/sounds/Success.wav'))
                    audio.play()
                    this.$swal({
                      title: 'Reembolso exitoso!',
                      text: 'Gracias.',
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                    this.emptyCart()
                  }).catch(error => {
                    if (error.response.data.messages[0]) {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Error',
                          icon: 'CoffeeIcon',
                          variant: 'danger',
                          text: error.response.data.messages[0],
                        },
                      })
                    }
                  })
              } else {
                console.log('slow connection')
                this.addOrder({ order: orderReady, orderType: 'sell', isRefund: 'true' })
                  .then(() => {
                    this.isActive = false
                    if (JSON.parse(localStorage.getItem('print')) === true) {
                      const order = {
                        order_type: {
                          payment: 'cash',
                          type: 'sell',
                        },
                        is_refund: true,
                        store: this.eliminarDiacriticos(this.cart[0].store_attributes.name),
                        products: this.cart.map(product => ({
                          name: this.eliminarDiacriticos(product.product_attributes.name),
                          variant: product.product_attributes.variant,
                          units: product.units,
                          unit_price: product.unit_price,
                        })),
                        subtotal: this.cartTotal - this.cartTotal * 0.16,
                        iva: this.cartTotal * 0.16,
                        total: this.cartTotal,
                        date: Date.now(),

                      }
                      this.print({ ...order })
                    }
                    // eslint-disable-next-line
                    const audio = new Audio(require('@/assets/sounds/Success.wav'))
                    audio.play()
                    this.$swal({
                      title: 'Reembolso exitoso!',
                      text: 'Gracias.',
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                    this.emptyCart()
                  }).catch(error => {
                    if (error.response.data.messages[0]) {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Error',
                          icon: 'CoffeeIcon',
                          variant: 'danger',
                          text: error.response.data.messages[0],
                        },
                      })
                    }
                  })
              }
            } else {
              this.isActive = false
            }
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            // An error occurred
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.active {
  background-color: $primary !important;
}

</style>
