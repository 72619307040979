<template>
  <div class="service-grid">
    <!-- <div class="service-card search-by-category">
      <i class="fas fa-2x fa-trash mb-1" />
      Buscar por categoría
    </div> -->
    <!-- <div class="service-card mobile-recharge">
      <i class="fas fa-2x fa-mobile mb-1" />
      Recarga tiempo aire
    </div> -->
    <cash-withdrawal />

    <!-- <div class="service-card codi-card">
      <i class="fas fa-2x fa-qrcode mb-1" />
      Pago con CoDi
    </div> -->
    <supplier-restock />

    <supplier-payment />

    <product-inventory v-if="userData.role_name === 'establishment_admin'" />
    <!-- <div class="service-card supplier-card">
      <i class="fas fa-2x fa-home mb-1" />
      Pago de servicios
    </div> -->
    <return-button />

    <services-payment />

    <airtime-recharges />

    <payment-loan-order />
  </div>
</template>

<script>
import ReturnButton from '@/views/pos/services/ReturnButton.vue'
import CashWithdrawal from '@/views/pos/services/CashWithrawal.vue'
import SupplierRestock from '@/views/pos/services/SupplierRestock.vue'
import SupplierPayment from '@/views/pos/services/SupplierPayment.vue'
import ServicesPayment from '@/views/pos/services/ServicesPayment.vue'
import AirtimeRecharges from '@/views/pos/services/AirtimeRecharges.vue'
import ProductInventory from '@/views/pos/services/ProductInventory.vue'
import PaymentLoanOrder from '@/views/pos/services/PaymentLoanOrder.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ReturnButton,
    CashWithdrawal,
    SupplierRestock,
    SupplierPayment,
    ServicesPayment,
    AirtimeRecharges,
    ProductInventory,
    PaymentLoanOrder,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.fetchEmidaProducts()
    this.fetchRecommenders()
  },
  methods: {
    ...mapActions('emidaProducts', ['fetchEmidaProducts']),
    ...mapActions('recommenders', ['fetchRecommenders']),
  },
}
</script>

<style lang="scss">
.service-grid {
  display: flex;
  flex-wrap: wrap;
  .service-card {
    width: 157px;
    height: 150px;
    margin: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 16px;
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    transition: transform .2s ease;
    &:hover {
      transform: scale(108%);
    }
  }

  .mobile-recharge {
    border: 2px solid rgba(#ADB1BD, 0.3);
  }
  .carsh-withdrawal {
    border: 2px solid rgba(#213DEE, 0.3);
  }
  .codi-card {
    border: 2px solid rgba(#485ff1, 0.3);
  }
  .supplier-card {
    border: 2px solid rgba(#48f1e9, 0.3);
  }
  .search-by-category {
    border: 2px solid rgba(#e48bd0, 0.3);
  }
  .return-card {
    border: 2px solid rgba(#f56767, 0.3);
  }
}

@media (max-width: 320px) {
  .service-grid {
    .service-card {
      width: 105px;
      height: 105px;
      font-size: 10px;
    }
  }
}

@media (320px <= width <= 400px) {
  .service-grid {
    .service-card {
      width: 120px;
      height: 120px;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .service-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
}
</style>
